import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setMortgageAmount, setMortgageInterest } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import OverlineComponent from "../../typography/overline_component";
import { DEFAULT_MORTGAGE_INTEREST_MAX, DEFAULT_MORTGAGE_MAX } from "../../../defaults";

export default function HousingMortgageStepComponent(props) {
    const { amount, interest } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData.household.mortgages[props.index].amount,
            interest: state.userEditingData.household.mortgages[props.index].interest,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAmount(value: number) {
        props.onValidate(value >= 18);
        dispatch(setMortgageAmount({ index: props.index, value }));
    }

    function onChangeInterest(value: number) {
        props.onValidate(value > 0);
        const interest = value / 100;
        dispatch(setMortgageInterest({ index: props.index, value: interest }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("housing:mortgage-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={"housing_mortgage_" + props.index + "_amount"}
                unit={t("common:sek")}
                onChange={onChangeAmount}
                min={0}
                step={50000}
                defaultMax={DEFAULT_MORTGAGE_MAX}
                label={t("housing:input-mortgage-amount")}
                value={amount}
            ></NumberComponent>
            <NumberComponent
                name={"housing_mortgage_" + props.index + "_interest"}
                unit="%"
                onChange={onChangeInterest}
                min={0}
                step={0.05}
                defaultMax={DEFAULT_MORTGAGE_INTEREST_MAX}
                label={t("housing:input-mortgage-interest")}
                value={interest * 100}
            ></NumberComponent>
        </IndataStepComponent>
    );
}
