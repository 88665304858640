/* eslint-disable @typescript-eslint/no-explicit-any */
import { isElementInViewport } from "./viewport";

export default class ActivityWatcher {
    activityEvents: Array<string> = ["mousedown", "mousemove", "keydown", "touchstart"];
    idleTimer: any = null;
    clockTimer: any = null;
    clockTime = 0;
    reportInterval = 10;
    idleTimeout = 30;
    currentContainer: HTMLElement;
    isStarted = false;

    onActivityCallback: () => any;
    onScrollCallback: () => any;
    onVisibilityChangeCallback: () => any;

    onEvent: (activityTime: number) => void;

    constructor(onEvent: (activityTime: number) => void, reportInterval?: number, idleTimeout?: number) {
        this.onActivityCallback = this.onActivity.bind(this);
        this.onScrollCallback = this.onScroll.bind(this);
        this.onVisibilityChangeCallback = this.onVisibilityChange.bind(this);
        this.onEvent = onEvent;
        this.reportInterval = reportInterval ?? 0;
        this.idleTimeout = idleTimeout ?? 30;
    }

    watch(container: HTMLElement) {
        this.currentContainer = container;

        if (this.reportInterval <= 0 || this.idleTimeout <= 0) {
            return;
        }

        this.activityEvents.forEach((eventName) => {
            container.addEventListener(eventName, this.onActivityCallback, true);
        });

        document.addEventListener("scroll", this.onScrollCallback);
        document.addEventListener("visibilitychange", this.onVisibilityChangeCallback);
        document.addEventListener("webkitvisibilitychange", this.onVisibilityChangeCallback);
    }

    unwatch(container: HTMLElement) {
        document.removeEventListener("visibilitychange", this.onVisibilityChangeCallback);
        document.removeEventListener("webkitvisibilitychange", this.onVisibilityChangeCallback);
        document.removeEventListener("scroll", this.onScrollCallback);

        this.activityEvents.forEach((eventName) => {
            container.removeEventListener(eventName, this.onActivityCallback, true);
        });

        this.currentContainer = undefined;
    }

    onTick(): void {
        this.clockTime += 1;
        if (this.clockTime > 0 && this.clockTime % this.reportInterval === 0) {
            this.onEvent(this.clockTime);
        }
    }

    startTimer() {
        if (this.isStarted) {
            return;
        }

        this.clockTimer = setInterval(this.onTick.bind(this), 1000);

        this.idleTimer = setTimeout(this.setIdle.bind(this), this.idleTimeout * 1000 + 100);

        this.isStarted = true;
    }

    stopTimer() {
        if (!this.isStarted) {
            return;
        }

        clearTimeout(this.idleTimer);
        this.idleTimer = null;
        clearInterval(this.clockTimer);
        this.clockTimer = null;
        this.isStarted = false;
    }

    setIdle() {
        this.stopTimer();
    }

    onScroll() {
        if (!this.currentContainer) {
            return;
        }

        if (!isElementInViewport(this.currentContainer)) {
            this.setIdle();
        } else {
            this.onActivity();
        }
    }

    onVisibilityChange() {
        if (document.hidden || (document as any).webkitHidden) {
            this.setIdle();
        } else if (isElementInViewport(this.currentContainer)) {
            this.startTimer();
        }
    }

    onActivity() {
        if (!this.clockTimer) {
            this.startTimer();
        } else {
            clearTimeout(this.idleTimer);
            this.idleTimer = setTimeout(this.setIdle.bind(this), this.idleTimeout * 1000 + 100);
        }
    }
}
