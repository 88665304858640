/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { CSSTransition, Transition, SwitchTransition } from "react-transition-group";

export function CSSAnimation(props) {
    if ((window as any).Cypress) {
        if (props.in === false) {
            return null;
        }
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <CSSTransition {...props}>{props.children}</CSSTransition>;
}

export function AnimationTransition(props) {
    const { ...childProps } = props;

    if ((window as any).Cypress) {
        if (props.in === false) {
            return null;
        }
        if (typeof props.children === "function") {
            return <React.Fragment>{props.children("exited", childProps)}</React.Fragment>;
        }
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <Transition {...props}>{props.children}</Transition>;
}

export function AnimationSwitch(props) {
    if ((window as any).Cypress) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <SwitchTransition {...props}>{props.children}</SwitchTransition>;
}
