import * as React from "react";
import { AccessibleAnchor } from "../accessibility/accessible_components";

interface AddItemProps {
    label: string;
    onClick: () => void;
}

export default function AddItemComponent(props: AddItemProps) {
    const { label, onClick } = props;

    return (
        <AccessibleAnchor className="add-item-component icon-add" onClick={onClick}>
            {label}
        </AccessibleAnchor>
    );
}
