import * as React from "react";
import InputWizardComponent from "../input_wizard";
import { IRootState, saveLoan, setNavigation, ViewMode, Sections } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import LoanFirstStepComponent from "../steps/loan/loan_first_step";
import LoanSecondStepComponent from "../steps/loan/loan_second_step";
import StudentLoanStepComponent from "../steps/loan/loan_studentloan_step";
import PrivateLoanFirstStepComponent from "../steps/loan/loan_privateloan_first_step";
import PrivateLoanSecondStepComponent from "../steps/loan/loan_privateloan_second_step";
import CarLoanFirstStepComponent from "../steps/loan/loan_carloan_first_step";
import CarLoanSecondStepComponent from "../steps/loan/loan_carloan_second_step";
import { useTracking } from "react-tracking";
import { useDispatch } from "react-redux";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";

export default function LoanWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { studentLoans, privateLoans, carLoans } = useSelector((state: IRootState) => {
        return {
            studentLoans: state.userEditingData?.loans?.studentLoans || [],
            privateLoans: state.userEditingData?.loans?.privateLoans || [],
            carLoans: state.userEditingData?.loans?.carLoans || [],
        };
    });

    function goToResult() {
        dispatch(saveLoan());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.LOAN));
        dispatch(
            setNavigation({
                viewMode: ViewMode.Result,
                section: Sections.Loans,
            }),
        );
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.LOAN));
    }

    const steps = [<LoanFirstStepComponent key="first"></LoanFirstStepComponent>, <LoanSecondStepComponent key="second"></LoanSecondStepComponent>];

    for (let key = 0; key < studentLoans.length; ++key) {
        steps.push(<StudentLoanStepComponent key={"studentloan_" + key} index={key}></StudentLoanStepComponent>);
    }

    for (let key = 0; key < privateLoans.length; ++key) {
        steps.push(<PrivateLoanFirstStepComponent key={"privateloan_" + key + "_first"} index={key}></PrivateLoanFirstStepComponent>);
        steps.push(<PrivateLoanSecondStepComponent key={"privateloan_" + key + "_second"} index={key}></PrivateLoanSecondStepComponent>);
    }

    for (let key = 0; key < carLoans.length; ++key) {
        steps.push(<CarLoanFirstStepComponent key={"carloan_" + key + "_first"} index={key}></CarLoanFirstStepComponent>);
        steps.push(<CarLoanSecondStepComponent key={"carloan_" + key + "_second"} index={key}></CarLoanSecondStepComponent>);
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            {steps}
        </InputWizardComponent>
    );
}
