import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IncompleteStateScenario, IScenarioProps, ScenarioTemplate } from "../scenarios";
import { ChildHouseholdRate } from "../../models/user_data";
import SelectComponent from "../form_controls/select_component";
import AddItemComponent from "../form_controls/add_item_component";
import { IRootState, resetAdditionalChildScenario, setAdditionalChildScenario } from "../../reducers/rootReducer";
import { ScenarioEvent } from "../../utility/analytics";

const getAdditionalChildScenarioData = (state: IRootState) => {
    return state.scenarioData?.additionalChild || [{ age: undefined, householdRate: ChildHouseholdRate.FULL_TIME }];
};

export const AdditionalChildScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;
    const userData = useSelector((state: IRootState) => state.userData?.kalp);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scenarioChildren = useSelector(getAdditionalChildScenarioData);
    const scenarioActivated = Number.isFinite(scenarioChildren[0].age) || scenarioChildren.length > 1;

    if (!userData) {
        return (
            <IncompleteStateScenario
                name={t("scenario:additional-child-header")}
                onBack={onBack}
                description={t("scenario:additional-child-incomplete-description")}
                category={t("dynamic:family")}
            />
        );
    }

    const birthItems = (selectIndex: number) => {
        const yearOfToday = new Date().getFullYear();
        const birthItems = [];
        for (let index = 0; index <= 10; index++) {
            const childAge = (index * -1).toString();
            const birthYear = (yearOfToday + index).toString();
            birthItems.push({
                id: selectIndex + childAge.toString(),
                value: childAge,
                label: birthYear,
            });
        }
        return birthItems;
    };

    const updateAdditionalChild = (index, item) => {
        onEngagement(ScenarioEvent.ADDITIONAL_CHILD);
        const childAge = Number.parseInt(item.value);
        scenarioChildren[index] = {
            age: childAge,
            householdRate: ChildHouseholdRate.FULL_TIME,
        };
        dispatch(setAdditionalChildScenario({ value: scenarioChildren }));
    };

    const resetScenario = () => {
        dispatch(resetAdditionalChildScenario());
    };

    const addAdditionalChild = () => {
        onEngagement(ScenarioEvent.ADDITIONAL_CHILD);
        scenarioChildren.push({
            age: undefined,
            householdRate: ChildHouseholdRate.FULL_TIME,
        });
        dispatch(setAdditionalChildScenario({ value: scenarioChildren }));
    };

    const dropDownListElements = scenarioChildren.map((child, index) => {
        const childIndex = userData.children ? userData.children.length + index + 1 : index + 1;
        return (
            <SelectComponent
                label={t("scenario:additional-child-select-label", {
                    index: childIndex,
                })}
                key={index}
                id={"additional-child-" + index}
                placeholder={t("scenario:additional-child-default-option-label")}
                value={isFinite(child?.age) ? index + child.age.toString() : "none"}
                items={birthItems(index)}
                onChange={updateAdditionalChild.bind(this, index)}
            />
        );
    });

    return (
        <ScenarioTemplate
            name={t("scenario:additional-child-header")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActivated}
            category={t("dynamic:family")}
        >
            {dropDownListElements}
            {dropDownListElements.length < 10 && (
                <div className="add-item-container">
                    <AddItemComponent label={t("scenario:add-additional-child-button")} onClick={addAdditionalChild}></AddItemComponent>
                </div>
            )}
        </ScenarioTemplate>
    );
};
