import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { AccessibleIdiomaticText, AccessibleLi } from "./accessibility/accessible_components";
import { AnimationSwitch, CSSAnimation } from "./wrappers/animations";

export default function SlidesComponent(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const numberOfSlides = props.children?.length || 0;

    const selectSlide = (index: number) => {
        setActiveIndex(index);
    };

    if (activeIndex >= numberOfSlides) {
        setActiveIndex(0);
    }

    const activeSlide = props.children[activeIndex];
    const activeKey = activeSlide?.key;
    const slideKey = activeKey + (props.animationState || "");

    useEffect(() => props.onSlideChange(activeKey), [activeKey]);

    if (!activeSlide) {
        return null;
    }

    const nextSlide = () => {
        let idx = activeIndex + 1;
        if (idx >= numberOfSlides) {
            idx = 0;
        }
        setActiveIndex(idx);
    };

    const prevSlide = () => {
        let idx = activeIndex - 1;
        if (idx < 0) {
            idx = numberOfSlides - 1;
        }
        setActiveIndex(idx);
    };

    return (
        <div className="slides-container">
            <AnimationSwitch>
                <CSSAnimation key={slideKey} classNames="animate-fade" timeout={800} in={true}>
                    <div className="slide-container">{activeSlide}</div>
                </CSSAnimation>
            </AnimationSwitch>
            {numberOfSlides > 1 ? (
                <ul className="slides-indicators">
                    <AccessibleIdiomaticText className="icon-hint-arrow prev-slide" onClick={prevSlide}></AccessibleIdiomaticText>
                    {props.children.map((child, index) => {
                        const active = index === activeIndex;
                        return (
                            <AccessibleLi
                                key={index}
                                className={classNames("slide-indicator", {
                                    "active-slide": active,
                                })}
                                onClick={selectSlide.bind(null, index)}
                            ></AccessibleLi>
                        );
                    })}
                    <AccessibleIdiomaticText className="icon-hint-arrow next-slide" onClick={nextSlide}></AccessibleIdiomaticText>
                </ul>
            ) : null}
        </div>
    );
}
