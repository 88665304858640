import { tipsModule } from "../../utility/module_store";
import { IRootState } from "../../reducers/rootReducer";
import { MortgageCalculationType } from "../../models/mortgage";
import { isKalpPositiveWithExtraDownpaymentAsIncome, LowKalpTipsComponent } from "./low_kalp_component";
import { isBankKalpNegativeOrZero, isBankKalpPositivForLTV } from "../../functions/kalp";

function isTipsActive(state: IRootState): boolean {
    return (
        isBankKalpNegativeOrZero(state) &&
        !isBankKalpPositivForLTV(state, 1 - state.minDownpaymentRate) &&
        isBankKalpPositivForLTV(state, state.minLTVForTips) &&
        isKalpPositiveWithExtraDownpaymentAsIncome(state)
    );
}

export default tipsModule(
    "low-kalp-move-loan",
    (state: IRootState) => !!state.userData && state.userData.household.calculationType === MortgageCalculationType.move && isTipsActive(state),
)(LowKalpTipsComponent);
