/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { IRootState } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import { constructUserDataInterface, IUserDataInterface } from "../../user_data_interface";
import { useTracking } from "react-tracking";
import { trackLead } from "../../utility/analytics";
import DisclaimerComponent from "../typography/disclaimer_component";
import { AccessibleButton } from "../../components/accessibility/accessible_components";

export interface CustomOfferConfig {
    name: string;
    header: string;
    content: string;
    buttonText: string;
    disclaimer: string;
    onClick: (userData: IUserDataInterface) => void;
}
export interface CustomOfferProps {
    config: CustomOfferConfig;
}

export default function CustomOfferComponent(props: CustomOfferProps) {
    const { config } = props;
    const tracking = useTracking();

    const { userData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
        };
    });

    function onClick() {
        if (config.onClick) {
            tracking.trackEvent(trackLead("click-offer", `offer-${config.name}`));
            config.onClick(constructUserDataInterface(userData));
        }
    }

    return (
        <React.Fragment>
            <h3 dangerouslySetInnerHTML={{ __html: config.header }} />
            <h4 dangerouslySetInnerHTML={{ __html: config.content }} />
            <AccessibleButton className="lead" onClick={onClick}>
                {config.buttonText}
            </AccessibleButton>
            <DisclaimerComponent>{config.disclaimer}</DisclaimerComponent>
        </React.Fragment>
    );
}
