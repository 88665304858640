import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IndataStepComponent from "../../indata_step";
import { IRootState, setHousingType } from "../../../reducers/rootReducer";
import { getHousingTypes } from "../../../functions/housing_type";
import RadioComponent from "../../../components/form_controls/radio_component";

export default function HousingTypeStepComponent(props) {
    const { housingType } = useSelector((state: IRootState) => {
        return { housingType: state.userEditingData?.household?.housingType };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onHouseForm(houseType) {
        new Promise<void>((resolve) => {
            dispatch(setHousingType(houseType));
            resolve();
        }).then(() => {
            props.nextStep();
        });
    }

    React.useEffect(() => {
        props.onValidate(false);
    });

    const options = getHousingTypes().map((type) => {
        return {
            value: type,
            label: t(`housing:input-${type}-type`),
        };
    });

    return (
        <IndataStepComponent active={props.active}>
            <RadioComponent label={t("housing:input-type-instruction")} value={housingType} options={options} onChange={onHouseForm}></RadioComponent>
        </IndataStepComponent>
    );
}
