import React from "react";
import { IRootState } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import { constructUserDataInterface, IUserDataInterface } from "../../user_data_interface";
import { useTracking } from "react-tracking";
import { trackLead } from "../../utility/analytics";
import DisclaimerComponent from "../typography/disclaimer_component";
import { AccessibleButton } from "../../components/accessibility/accessible_components";

export interface DefaultOfferConfig {
    name: string;
    description: string;
    offer: string;
    buttonText: string;
    disclaimer?: string;
    onClick: (userData: IUserDataInterface) => void;
}

export interface DefaultOfferProps {
    config: DefaultOfferConfig;
}

export default function DefaultOfferComponent(props: DefaultOfferProps) {
    const { config } = props;
    const tracking = useTracking();

    const { userData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
        };
    });

    function onClick() {
        if (config.onClick) {
            tracking.trackEvent(trackLead("click-offer", `offer-${config.name}`));
            config.onClick(constructUserDataInterface(userData));
        }
    }

    return (
        <React.Fragment>
            <h4>{config.description}</h4>
            <h2>{config.offer}</h2>
            <AccessibleButton className="lead" onClick={onClick}>
                {config.buttonText}
            </AccessibleButton>
            {config.disclaimer && <DisclaimerComponent>{config.disclaimer}</DisclaimerComponent>}
        </React.Fragment>
    );
}
