import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setAccountSaving, setAccountAmount } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import OverlineComponent from "../../typography/overline_component";
import { DEFAULT_SAVINGS_ACCOUNT_AMOUNT_MAX } from "../../../defaults";

export default function AccountStepComponent(props) {
    const { amount, saving } = useSelector((state: IRootState) => {
        return {
            amount: state.userEditingData?.savings?.accountAmount,
            saving: state.userEditingData?.savings?.accountMonthlySavings,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFirst(value: number) {
        props.onValidate(true);
        dispatch(setAccountAmount(value));
    }

    function onChangeSecond(value: number) {
        props.onValidate(true);
        dispatch(setAccountSaving(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("savings:input-accounts")}</OverlineComponent>
            <NumberComponent
                name={"savings_account_amount"}
                unit={t("common:sek")}
                onChange={onChangeFirst}
                min={0}
                step={10000}
                defaultMax={DEFAULT_SAVINGS_ACCOUNT_AMOUNT_MAX}
                label={t("savings:input-account-amount")}
                value={amount}
            ></NumberComponent>
            <NumberComponent
                name={"savings_account_saving"}
                unit={t("common:sek")}
                onChange={onChangeSecond}
                min={0}
                step={100}
                defaultMax={10000}
                label={t("savings:input-account-saving")}
                value={saving}
            ></NumberComponent>
        </IndataStepComponent>
    );
}
