import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setAdditionalHousingFees, setAdditionalHousingMaintenance } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../../typography/disclaimer_component";
import OverlineComponent from "../../typography/overline_component";

export default function KalpAdditionalHousingFeesStep(props) {
    const { fees, maintenance } = useSelector((state: IRootState) => {
        return {
            fees: state.userEditingData.kalp.additionalHousing.data.fees,
            maintenance: state.userEditingData.kalp.additionalHousing.data.maintenance,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeFees(value: number) {
        props.onValidate(value >= 0);
        dispatch(setAdditionalHousingFees(value));
    }

    function onChangeMaintenance(value: number) {
        props.onValidate(true);
        dispatch(setAdditionalHousingMaintenance(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("kalp:additional-housing")}</OverlineComponent>
            <DisclaimerComponent>{t("kalp:part-of-additional-housing")}</DisclaimerComponent>
            <NumberComponent
                name={"additional_housing_fees"}
                unit={t("common:sek")}
                onChange={onChangeFees}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("kalp:input-fees")}
                value={fees}
            ></NumberComponent>
            <NumberComponent
                name={"additional_housing_maintenance"}
                unit={t("common:sek")}
                onChange={onChangeMaintenance}
                min={0}
                step={100}
                defaultMax={20000}
                label={t("kalp:input-maintenance")}
                value={maintenance}
            ></NumberComponent>
            <DisclaimerComponent>{t("kalp:input-maintenance-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
