import insight from "./insight.json";
import housing from "./housing.json";
import common from "./common.json";
import dynamic from "./dynamic.json";
import kalp from "./kalp.json";
import loan from "./loan.json";
import savings from "./savings.json";
import prognosis from "./prognosis.json";
import proposition from "./proposition.json";
import scenario from "./scenario.json";
import aria from "./aria.json";
import tips from "./tips.json";

export default {
    common,
    dynamic,
    insight,
    housing,
    kalp,
    loan,
    savings,
    prognosis,
    proposition,
    scenario,
    aria,
    tips,
};
