import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { DataPoint, IResult } from "../models/result";
import { dynamicRounding } from "../utility/number_formatter";
import { resultIsEmpty } from "../utility/result_builder";
import DisclaimerComponent from "./typography/disclaimer_component";
import InViewAnimated from "./animation/InViewAnimated";
import { setNavigation, ViewMode, Sections } from "../reducers/rootReducer";
import { getFutureValue, renderScenarioFuture, renderScenarioNow } from "../utility/datapoints";
import { usePrevious } from "../utility/ref";
import { AccessibleButton } from "./accessibility/accessible_components";
import { NoTranslation } from "./wrappers/no_translation";

export interface MatrixProps {
    items?: IResult;
    section: Sections;
}

export default function PrognosisMatrixComponent(props: MatrixProps) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { items, section } = props;
    const [currentItems] = React.useState(items);
    const prevItems: IResult = usePrevious(currentItems);

    function goToIndata() {
        dispatch(setNavigation({ viewMode: ViewMode.Input, section: section }));
    }

    if (!items) {
        return (
            <div className="no-result">
                <label>{t("common:no-result")}</label>
                <AccessibleButton onClick={goToIndata}>{t(`prognosis:add-${section}-indata`)}</AccessibleButton>
            </div>
        );
    }

    const emptyResult = resultIsEmpty(items);
    if (emptyResult) {
        return (
            <div className="no-result">
                <label>{t(`prognosis:empty-${section}-results`)}</label>
                <AccessibleButton onClick={goToIndata}>{t(`prognosis:add-${section}-indata`)}</AccessibleButton>
            </div>
        );
    }

    const keys = Object.keys(items)
        .filter((key) => key !== "summary")
        .map((key) => {
            return {
                key,
                data: items[key],
                prevData: prevItems && prevItems[key],
            };
        });

    return (
        <ul className="prognosis-matrix" role="rowgroup">
            {keys.map(({ key, data, prevData }) => renderRow(key, data, prevData))}
        </ul>
    );

    function renderRow(key: string, itemData: DataPoint, prevItemData: DataPoint) {
        const dataCy = (name) => `${key}-${name}`;
        const showScenarioNow = renderScenarioNow(itemData);
        const showScenarioFuture = renderScenarioFuture(itemData);
        const SectionElement = document.querySelector("#econans-section-" + section) as HTMLElement;

        return (
            <li key={key} role="row">
                <div data-cy={dataCy("label")} role="rowheader">
                    <span className="dot"></span>
                    <span>
                        <p className="subtitle_4">{i18n.exists(`dynamic:${key}`) ? t(`dynamic:${key}`) : key}</p>
                        {i18n.exists(`dynamic:${key}-description`) && <DisclaimerComponent>{t(`dynamic:${key}-description`)}</DisclaimerComponent>}
                    </span>
                </div>
                <div data-cy={dataCy("now")} role="cell">
                    {showScenarioNow && (
                        <p data-cy="scenario-now" className="subtitle_3">
                            <InViewAnimated
                                inViewElement={SectionElement}
                                value={`${dynamicRounding(itemData.scenarioNow)} kr`}
                                noInitialAnimation={!prevItemData}
                            />
                        </p>
                    )}
                    <NoTranslation>
                        <p
                            data-cy="actual-now"
                            className={`subtitle_3 ${classNames({
                                actual: showScenarioNow,
                            })}`}
                        >
                            {`${dynamicRounding(itemData.now)} kr`}
                        </p>
                    </NoTranslation>
                </div>
                <div data-cy={dataCy("future")} role="cell">
                    {showScenarioFuture && (
                        <p data-cy="scenario-future" className="subtitle_4">
                            <InViewAnimated
                                inViewElement={SectionElement}
                                value={`${dynamicRounding(getFutureValue(itemData.scenarioFuture))} kr`}
                                noInitialAnimation={!prevItemData}
                            />
                        </p>
                    )}
                    <NoTranslation>
                        <p
                            data-cy="actual-future"
                            className={`subtitle_4 ${classNames({
                                actual: showScenarioFuture,
                            })}`}
                        >
                            {`${dynamicRounding(getFutureValue(itemData.future))} kr`}
                        </p>
                    </NoTranslation>
                </div>
                <div></div>
            </li>
        );
    }
}
