import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useTracking } from "react-tracking";
import { AccessibleDiv, AccessibleButton, AccessibleAnchor } from "./accessibility/accessible_components";
import { IRootState, IScenarioData, Sections, setSectionBasedOnScenario, setEvent } from "../reducers/rootReducer";
import { AdditionalChildScenario } from "./scenarios/additional_child";
import { AmortizationScenario } from "./scenarios/amortization";
import { InterestScenario } from "./scenarios/interest";
import { InvestmentScenario } from "./scenarios/investment";
import { MaintenanceScenario } from "./scenarios/maintenance";
import { MortgageScenario } from "./scenarios/mortgage";
import { RenovationScenario, isRenovationScenarioActive } from "./scenarios/renovation";
import { WorkPartTimeScenario } from "./scenarios/work_part_time";
import CaptionComponent from "./typography/caption_component";
import OverlineComponent from "./typography/overline_component";
import { ScenarioEvent, trackEngagement } from "../utility/analytics";
import useObserveHook from "../utility/inViewHook";
import { hasOccured } from "../utility/one_time_events";
import { AnimationSwitch, AnimationTransition, CSSAnimation } from "./wrappers/animations";
import ResetAllScenarios from "./reset_scenarios";
import { scrollToTarget } from "../utility/scroll_to_target";
import { getMortgage } from "../functions/household";

export interface IScenarioProps {
    onBack?: () => void;
    onEngagement?: (label: ScenarioEvent) => void;
}

export interface IScenariosProps {
    activeScenarios?: Array<string>;
}

const Scenarios = (props: IScenariosProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { userData, events, scenarioData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            events: state.events,
            scenarioData: state.scenarioData,
        };
    });
    const tracking = useTracking();
    const trackUsage = (label) => {
        const event = trackEngagement(label);

        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    };

    const [selectedScenario, selectScenario] = useState(ScenarioModes.List);
    const selectListMode = () => selectScenario(ScenarioModes.List);
    const selectSectionByScenario = (mode: ScenarioModes) => {
        selectScenario(mode);
        if (mode === ScenarioModes.AdditionalChild || mode === ScenarioModes.WorkPartTime) {
            dispatch(setSectionBasedOnScenario(Sections.Kalp));
        } else if (mode === ScenarioModes.Renovation || mode === ScenarioModes.Investment) {
            dispatch(setSectionBasedOnScenario(Sections.Savings));
        } else {
            dispatch(setSectionBasedOnScenario(Sections.Housing));
        }
    };

    const { ref, inView } = useObserveHook({
        threshold: 1,
        triggerOnce: !!userData, // Enable triggerOnce when we actually have the data
    });

    const scenarioProps = {
        onBack: selectListMode,
        onEngagement: trackUsage,
    };

    const [showResetQuestion, setShowResetQuestion] = useState(false);
    const showReset = (show: boolean) => {
        setShowResetQuestion(show);
    };

    if (props.activeScenarios && props.activeScenarios.length === 0) {
        return null;
    }

    const activeScenario = scenarioData ? isAnyScenarioActive(scenarioData) : false;

    return (
        <div
            className={classNames("container scenario-container", {
                disabled: !userData,
            })}
            ref={ref}
        >
            <div className="scenario-header">
                <h3>{t("scenario:section-header")}</h3>
                {activeScenario && selectedScenario === "List" && !showResetQuestion ? (
                    <label>
                        <AccessibleAnchor aria-label={t("scenario:reset")} onClick={() => setShowResetQuestion(true)}>
                            {t("scenario:reset")}
                        </AccessibleAnchor>
                    </label>
                ) : (
                    ""
                )}
            </div>
            {showResetQuestion ? (
                <ResetAllScenarios showReset={showReset}></ResetAllScenarios>
            ) : (
                <AnimationSwitch>
                    <CSSAnimation key={selectedScenario} classNames="animate-fade" timeout={300} in={true}>
                        <div
                            className={classNames("container-body scenario-body", {
                                disabled: !userData,
                            })}
                        >
                            {selectedScenario === ScenarioModes.Interest && <InterestScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.Loan && <MortgageScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.Amortization && <AmortizationScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.Maintenance && <MaintenanceScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.AdditionalChild && <AdditionalChildScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.Investment && <InvestmentScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.Renovation && <RenovationScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.WorkPartTime && <WorkPartTimeScenario {...scenarioProps} />}
                            {selectedScenario === ScenarioModes.List && (
                                <>
                                    {t("scenario:section-subheader") ? <h4>{t("scenario:section-subheader")}</h4> : null}
                                    <ScenarioList inView={inView} onSelect={selectSectionByScenario} activeScenarios={props.activeScenarios} />
                                </>
                            )}
                        </div>
                    </CSSAnimation>
                </AnimationSwitch>
            )}
            <div className="scenario-footer">
                {selectedScenario === "List" && userData ? (
                    <AccessibleDiv
                        className="show-for-small-screens-only"
                        onClick={scrollToResult}
                        onKeyPress={scrollToResult}
                        role="button"
                        tabIndex={0}
                        aria-label={t("aria:see-result")}
                    >
                        <p>{activeScenario ? t("scenario:see-updated-result") : t("scenario:back-to-result")}</p>
                        <button className="with-icon" onClick={scrollToResult}>
                            <i className="icon-arrow-up"></i>
                        </button>
                    </AccessibleDiv>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export const scrollToResult = () => {
    const result = document.getElementById("econans-results");
    result ? scrollToTarget(result) : null;
};

enum ScenarioModes {
    Disabled = "Disabled",
    AdditionalChild = "AdditionalChild",
    Amortization = "Amortization",
    Interest = "Interest",
    Investment = "Investment",
    List = "List",
    Loan = "Loan",
    Maintenance = "Maintenance",
    Renovation = "Renovation",
    WorkPartTime = "WorkPartTime",
}

const isScenarioActive = (scenarioData: IScenarioData, scenario: ScenarioModes) => {
    if (!scenarioData) {
        return false;
    }

    switch (scenario) {
        case ScenarioModes.Interest:
            return !!scenarioData.mortgageInterestRate;
        case ScenarioModes.Loan:
            return !!scenarioData.mortgageData;
        case ScenarioModes.Amortization:
            return !!scenarioData.mortgageAmortization;
        case ScenarioModes.Maintenance:
            return Number.isFinite(scenarioData.mortgageMaintenance);
        case ScenarioModes.AdditionalChild:
            return !!scenarioData.additionalChild;
        case ScenarioModes.Investment:
            return Number.isFinite(scenarioData.investmentMonthly);
        case ScenarioModes.Renovation:
            return isRenovationScenarioActive(scenarioData.renovationCost, scenarioData.renovationYear);
        case ScenarioModes.WorkPartTime:
            return !!scenarioData.adultsWorkPartTime;
        default:
            return false;
    }
};

const isAnyScenarioActive = (scenarioData) => {
    return Object.keys(ScenarioModes)
        .filter((key) => !Number.isFinite(key))
        .map((mode) => isScenarioActive(scenarioData, mode as ScenarioModes))
        .some((active) => active);
};

const ScenarioList = (props) => {
    const { onSelect, inView } = props;
    const { t } = useTranslation();

    const { userData, scenarioData } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            scenarioData: state.scenarioData,
        };
    });

    if (!userData) {
        return (
            <>
                <i className="icon-placeholder icon-scenario"></i>
                <h4>{t("scenario:incomplete-description")}</h4>
            </>
        );
    }

    const scenarioListItems = [
        {
            name: "loan",
            icon: "house",
            mode: ScenarioModes.Loan,
            label: t("scenario:list-mortgage-header"),
            hasData: !!userData,
        },
        {
            name: "interest",
            icon: "interest",
            mode: ScenarioModes.Interest,
            label: t("scenario:list-interest-header"),
            hasData: getMortgage(userData, scenarioData?.mortgageData) > 0,
        },
        {
            name: "amortization",
            icon: "amortization",
            mode: ScenarioModes.Amortization,
            label: t("scenario:list-amortization-header"),
            hasData: getMortgage(userData, scenarioData?.mortgageData) > 0,
        },
        {
            name: "maintenance",
            icon: "maintenance",
            mode: ScenarioModes.Maintenance,
            label: t("scenario:list-maintenance-header"),
            hasData: !!userData,
        },
        {
            name: "work-part-time",
            icon: "work-part-time",
            mode: ScenarioModes.WorkPartTime,
            label: t("scenario:list-work-part-time"),
            hasData: !!userData,
        },
        {
            name: "additional-child",
            icon: "child",
            mode: ScenarioModes.AdditionalChild,
            label: t("scenario:list-additional-child-header"),
            hasData: !!userData?.kalp,
        },
        {
            name: "investment",
            icon: "investment",
            mode: ScenarioModes.Investment,
            label: t("scenario:list-investment-header"),
            hasData: !!userData?.savings,
        },
        {
            name: "renovation",
            icon: "renovation",
            mode: ScenarioModes.Renovation,
            label: t("scenario:list-renovation-header"),
            hasData: !!userData?.savings,
        },
    ];

    let filteredScenarioListItems = scenarioListItems;

    if (props.activeScenarios && props.activeScenarios) {
        filteredScenarioListItems = scenarioListItems
            .filter((item) => {
                return (props.activeScenarios as Array<string>).indexOf(item.name) >= 0;
            })
            .sort((item1, item2) => {
                const i1 = (props.activeScenarios as Array<string>).indexOf(item1.name);
                const i2 = (props.activeScenarios as Array<string>).indexOf(item2.name);
                return i1 - i2;
            });
    }

    const triggerAnimation = inView && !!userData;
    return (
        <>
            {/* Timeout should match css transition-duration */}
            <AnimationTransition in={triggerAnimation} timeout={300}>
                {(status) => (
                    <div className={classNames("scenario-list", status)} onTouchStart={() => void 0}>
                        {filteredScenarioListItems.map(({ name, icon, mode, label, hasData }) => (
                            <ScenarioListItem
                                key={name}
                                name={name}
                                onClick={() => onSelect(mode)}
                                icon={icon}
                                label={label}
                                hasData={hasData}
                                isActive={isScenarioActive(scenarioData, mode)}
                            />
                        ))}
                    </div>
                )}
            </AnimationTransition>
        </>
    );
};

const ScenarioListItem = (props) => {
    const { t } = useTranslation();

    const { name, icon, label, isActive, onClick, hasData } = props;

    return (
        <AccessibleDiv
            className={classNames("scenario-list-item", { disabled: !hasData })}
            data-cy={`${name}-scenario`}
            onClick={onClick}
            aria-label={t("aria:go-to-scenario", { label })}
        >
            <div className={classNames("scenario-list-item-icon", `icon-${icon}`, { active: isActive })}></div>
            <CaptionComponent>{label}</CaptionComponent>
        </AccessibleDiv>
    );
};

export const IncompleteStateScenario = (props) => {
    const { name, description, onBack, category } = props;
    const { t } = useTranslation();

    return (
        <ScenarioTemplate name={name} onBack={onBack} onReset={null} scenarioActivated={false} category={category}>
            <p data-cy="incomplete-state-description">{description ? description : t("scenario:incomplete-description")}</p>
        </ScenarioTemplate>
    );
};

export const ScenarioTemplate = (props) => {
    const { name, onBack, onReset, scenarioActivated, children, category } = props;
    const { t } = useTranslation();

    return (
        <div className="scenario-input-container">
            <div>
                <OverlineComponent>{category + t("dynamic:breadcrumb-delimiter") + name}</OverlineComponent>
                {children}
            </div>
            <div className="actions">
                {onReset && (
                    <AccessibleButton
                        data-cy="scenario-reset-btn"
                        className={`outline ${scenarioActivated ? "" : "disabled"}`}
                        onClick={onReset}
                        disabled={!scenarioActivated}
                    >
                        {t("scenario:reset")}
                    </AccessibleButton>
                )}
                {onBack && <AccessibleButton onClick={onBack}>{onReset ? t("scenario:ok") : t("scenario:back-to-list")}</AccessibleButton>}
            </div>
        </div>
    );
};

export default Scenarios;
