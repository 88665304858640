import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { setHousingAdults, IRootState } from "../../../reducers/rootReducer";
import NumberStepComponent from "../../form_controls/number_step_component";
import { useTranslation } from "react-i18next";

export default function HousingOccupantsStepComponent(props) {
    const { adults } = useSelector((state: IRootState) => {
        return {
            adults: state.userEditingData?.household?.adults?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAdults(value: number) {
        props.onValidate(value > 0);
        dispatch(setHousingAdults(value));
    }

    React.useEffect(() => {
        props.onValidate(adults > 0);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberStepComponent
                name="housing_adults"
                icon="adult"
                onChange={onChangeAdults}
                min={1}
                max={2}
                unit={t("common:pcs")}
                label={t("housing:input-household-adults")}
                subLabel={t("housing:input-adults")}
                value={adults}
            ></NumberStepComponent>
        </IndataStepComponent>
    );
}
