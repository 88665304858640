import * as React from "react";
import { WithTranslation, withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ViewMode, IRootState, setselectedViewMode } from "../reducers/rootReducer";

import { AccessibleButton } from "./accessibility/accessible_components";

export interface TopMenuProps extends WithTranslation {
    toggleInput: (viewMode: ViewMode) => void;
    selectedViewMode: ViewMode;
}

class TopMenu extends React.Component<TopMenuProps> {
    render() {
        const { t } = this.props;

        return (
            <div className="prognosis-header">
                <h3>{t("common:my-economy")}</h3>
                <EditInput {...this.props} />
            </div>
        );
    }
}

function EditInput(props: TopMenuProps) {
    const { selectedViewMode, toggleInput } = props;
    const { t } = useTranslation();

    if (selectedViewMode == ViewMode.Input) return null;

    return (
        <div className="icon-element-container">
            <AccessibleButton
                data-cy="edit-input"
                aria-label={t("aria:edit-input")}
                className="outline icon-pencil"
                onClick={() => toggleInput(selectedViewMode)}
            >
                {t("common:edit")}
            </AccessibleButton>
        </div>
    );
}

function mapStateToProps(state: IRootState) {
    const { selectedViewMode } = state;

    return { selectedViewMode };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleInput: (currentViewMode: ViewMode) => {
            const isViewModeInput = currentViewMode === ViewMode.Input;
            const viewMode = isViewModeInput ? ViewMode.Result : ViewMode.Input;
            dispatch(setselectedViewMode(viewMode));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopMenu));
