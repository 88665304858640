import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setAdditionalHousingPropertyTax } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import DisclaimerComponent from "../../typography/disclaimer_component";
import OverlineComponent from "../../typography/overline_component";
import { DEFAULT_PROPERTY_TAX } from "../../../defaults";
import { formatLocalAmount } from "../../../utility/number_formatter";

export default function KalpAdditionalHousingPropertyTaxStep(props) {
    const { propertyTax } = useSelector((state: IRootState) => {
        return {
            propertyTax: state.userEditingData.kalp.additionalHousing.data.propertyTax || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeSize(value: number) {
        props.onValidate(value >= 0);
        dispatch(setAdditionalHousingPropertyTax(value));
    }

    React.useEffect(() => {
        props.onValidate(propertyTax >= 0);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("kalp:additional-housing")}</OverlineComponent>
            <DisclaimerComponent>{t("kalp:part-of-additional-housing")}</DisclaimerComponent>
            <NumberComponent
                name="additional_housing_property_tax"
                unit={t("common:sek")}
                onChange={onChangeSize}
                min={0}
                step={100}
                defaultMax={18000}
                label={t("kalp:input-property-tax")}
                value={propertyTax}
            ></NumberComponent>
            <DisclaimerComponent>
                {t("kalp:input-property-tax-disclaimer", { propertyTax: formatLocalAmount(DEFAULT_PROPERTY_TAX) })}
            </DisclaimerComponent>
        </IndataStepComponent>
    );
}
