import { DataPoint, IResult } from "../models/result";
import { sumDatapoints } from "./datapoints";

class ResultBuilder {
    private years = 0;
    private datapoints = {};

    constructor(years: number) {
        this.years = years;
    }

    set(key: string, value: DataPoint) {
        this.datapoints[key] = value;
    }

    getResult(): IResult {
        const values: Array<DataPoint> = Object.values(this.datapoints);

        const summary =
            values.length > 0
                ? sumDatapoints(values)
                : {
                      now: 0,
                      future: Array.from({ length: this.years + 1 }, () => 0),
                  };

        return {
            summary,
            ...this.datapoints,
        };
    }
}

export default ResultBuilder;

export const resultIsEmpty = (result: IResult | undefined) => {
    return result && Object.keys(result).length === 1 && result.summary;
};

export const resultIsAvailable = (result: IResult | undefined) => {
    return result && Object.keys(result).length > 1;
};
