import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setHousingFee } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import { MIN_FEE, FEE_STEP, MAX_FEE } from "../../../defaults";
import DisclaimerComponent from "../../typography/disclaimer_component";

export default function HousingFeeStepComponent(props) {
    const { fee } = useSelector((state: IRootState) => {
        return {
            fee: state.userEditingData?.household?.fee || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeSize(value: number) {
        props.onValidate(value >= MIN_FEE);
        dispatch(setHousingFee(value));
    }

    React.useEffect(() => {
        props.onValidate(fee >= MIN_FEE);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="housing_fee"
                unit={t("common:sek")}
                onChange={onChangeSize}
                min={MIN_FEE}
                step={FEE_STEP}
                defaultMax={MAX_FEE}
                label={t("housing:input-fee")}
                value={fee}
            ></NumberComponent>
            <DisclaimerComponent>{t("housing:input-fee-disclaimer")}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
