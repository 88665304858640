const duration = 800;

export const scrollToTarget = function (target: HTMLElement) {
    const top = target.getBoundingClientRect().top;
    const startPosition = window.pageYOffset;
    const diff = top;
    let startTime = null;
    let requestId;

    const easeOutCubic = (t: number) => --t * t * t + 1;
    const loop = function (currentTime: number) {
        if (!startTime) {
            startTime = currentTime;
        }

        // Elapsed time in milliseconds
        const time = currentTime - startTime;
        const percent = Math.min(time / duration, 1);
        window.scrollTo(0, startPosition + diff * easeOutCubic(percent));

        if (time < duration) {
            // Continue moving
            requestId = window.requestAnimationFrame(loop);
        } else {
            window.cancelAnimationFrame(requestId);
        }
    };
    requestId = window.requestAnimationFrame(loop);
};
