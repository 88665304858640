import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IRootState, setMortgageScenario } from "../../reducers/rootReducer";
import { getLoanWithPositiveKalp, getMortgage, getMortgageExpense } from "../../functions/household";
import { formatLocalAmount } from "../../utility/number_formatter";
import { forecastKalpWithInterest, isBankKalpPositivForLTV } from "../../functions/kalp";
import { applyBankKalpScenariosToUserData, convertToPercent } from "../../functions/calculations";
import { getHouseholdIncome, getHousingPriceByCalculationType } from "../../selectors/household";
import { MortgageCalculationType } from "../../models/mortgage";
import DisclaimerComponent from "../../components/typography/disclaimer_component";
import { processMarkdown } from "../../utility/markdown_processor";
import { ITipsModuleProps } from "../../utility/module_store";
import { minAmortizationRate } from "../../functions/installment";
import { AccessibleButton } from "../../components/accessibility/accessible_components";
import { householdIncomeForecastForYear } from "../../functions/income";
import { MONTHS_IN_YEAR } from "../../defaults";

export function isKalpPositiveWithExtraDownpaymentAsIncome(state: IRootState): boolean {
    const currentUserData = applyBankKalpScenariosToUserData(state.userData, state.scenarioData, state.calculationInterestRate);
    const { netIncome } = householdIncomeForecastForYear(currentUserData, 0);
    const yearlyNetIncome = netIncome * MONTHS_IN_YEAR;
    const housingValue = getHousingPriceByCalculationType(currentUserData);
    const currentMortgageValue = getMortgage(currentUserData);
    const newMortgage = currentMortgageValue - yearlyNetIncome;
    const newLTV = newMortgage / housingValue;

    return isBankKalpPositivForLTV(state, newLTV);
}

export function LowKalpTipsComponent(props: ITipsModuleProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { calculationInterestRate, userData, scenarioData } = props.rootState;

    const currentUserData = applyBankKalpScenariosToUserData(userData, scenarioData, calculationInterestRate);
    const mortgageExpense = getMortgageExpense(currentUserData, calculationInterestRate);
    const kalpSumNow = Math.abs(forecastKalpWithInterest(currentUserData, calculationInterestRate));
    const maxMortgageExpenseForPosKalp = mortgageExpense - kalpSumNow;
    const userDefinedLoan = getMortgage(currentUserData) || 0;

    const decreaseLoanParams = {
        userData: currentUserData,
        interest: calculationInterestRate,
        loan: Math.ceil(userDefinedLoan / 1000) * 1000,
        reduce: 1024000,
        minReduceValue: 1000,
        reduceDivisor: 4,
        expenseToRecede: maxMortgageExpenseForPosKalp,
    };
    const loanWithPositiveKalp = Math.floor(getLoanWithPositiveKalp(decreaseLoanParams) / 1000) * 1000;
    const downpaymentDelta = userDefinedLoan - loanWithPositiveKalp;
    const housingValue = getHousingPriceByCalculationType(currentUserData);
    const newDownpayment = housingValue - loanWithPositiveKalp;
    const newLTVRatio = loanWithPositiveKalp / housingValue;

    const newAmortization = getMortgageExpense(currentUserData, 0, loanWithPositiveKalp);

    const additionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortgage?.amount ?? 0;
    const minAmortizationInput = {
        price: housingValue,
        loan: loanWithPositiveKalp,
        income: getHouseholdIncome(currentUserData) || 0,
        additionalMortgageAmount,
    };
    const newAmortizationRate = convertToPercent(minAmortizationRate(minAmortizationInput));

    const discountedInterest = props.configuration?.discounts
        .sort((first, second) => first.rate - second.rate)
        .find((discount) => discount.rate >= newLTVRatio);
    const mortgageInterestRate = Number.isFinite(discountedInterest?.interest) && { interest: discountedInterest.interest };

    const calculationType = userData.household.calculationType;
    const tipsText =
        calculationType === MortgageCalculationType.new
            ? t("tips:increase-downpayment-to-positive-kalp-new-loan", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
                  housingValue: formatLocalAmount(housingValue),
              })
            : t("tips:increase-downpayment-to-positive-kalp-move-loan", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
              });
    const tipsDisclaimer =
        calculationType === MortgageCalculationType.new
            ? t("tips:increase-downpayment-to-positive-kalp-new-loan-disclaimer", {
                  newDownpayment: formatLocalAmount(newDownpayment),
                  newLoan: formatLocalAmount(loanWithPositiveKalp),
                  calculationInterestRate: formatLocalAmount(calculationInterestRate * 100, 0, 1),
                  newAmortization: formatLocalAmount(newAmortization),
                  newAmortizationRate,
              })
            : t("tips:increase-downpayment-to-positive-kalp-move-loan-disclaimer", {
                  downpaymentDelta: formatLocalAmount(downpaymentDelta),
                  newLoan: formatLocalAmount(loanWithPositiveKalp),
                  calculationInterestRate: formatLocalAmount(calculationInterestRate * 100, 0, 1),
              });

    const setScenario = () => {
        props.onActivated();
        dispatch(
            setMortgageScenario({
                value: housingValue,
                mortgage: loanWithPositiveKalp,
                mortgageInterestRate,
            }),
        );
    };

    return (
        <div>
            <h4
                dangerouslySetInnerHTML={{
                    __html: processMarkdown(tipsText),
                }}
            ></h4>
            <AccessibleButton onClick={setScenario}>{t("tips:set-scenario")}</AccessibleButton>
            <DisclaimerComponent>
                <span
                    dangerouslySetInnerHTML={{
                        __html: processMarkdown(tipsDisclaimer),
                    }}
                ></span>
            </DisclaimerComponent>
            <DisclaimerComponent>{t("tips:no-offer")}</DisclaimerComponent>
        </div>
    );
}
