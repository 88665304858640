import UserData, { Person, HouseholdData } from "../models/user_data";
import { MortgageCalculationType } from "../models/mortgage";
import { Maybe } from "../utility/types";
import { forecastPerson, getNetIncome } from "../functions/income";

export function getAdult(userData: UserData, index: number): Person {
    return userData.household.adults[index];
}

export function getCurrentHouseholdIncome(userData: UserData): number {
    const householdIncome = getScenarioHouseholdIncome(userData.scenarioDefinedAdults) || getHouseholdIncome(userData) || 0;

    return householdIncome;
}

export function getScenarioHouseholdIncome(adults: Array<Person>) {
    if (!adults) {
        return false;
    }

    return adults.reduce((acc, person) => acc + forecastPerson(person, 0).income, 0);
}

export function getHouseholdIncome(userData: UserData): Maybe<number> {
    const adults = userData.household?.adults;

    if (!adults) {
        return;
    }

    return adults.reduce((acc, person) => acc + person.income, 0);
}

export function getNetHouseholdIncome(userData: UserData): Maybe<number> {
    const adults = userData.household?.adults;

    if (!adults) {
        return;
    }

    return adults.reduce((acc, person) => acc + getNetIncome(person), 0);
}
type GetImpliedMortgageAmountParams = Pick<HouseholdData, "price" | "downpayment">;
export function getImpliedMortgageAmount(params: GetImpliedMortgageAmountParams): number {
    return params.price - params.downpayment;
}

export function getTotalExistingMortgageAmount(userData: UserData): number {
    return userData.household.mortgages.reduce((acc, mortgage) => acc + mortgage.amount, 0);
}

export function getTotalMortgageAmount(userData: UserData): number {
    if (!userData.household) {
        return 0;
    }

    return userData.household.calculationType === MortgageCalculationType.new
        ? getImpliedMortgageAmount({
              price: getPrice(userData),
              downpayment: getDownpayment(userData),
          })
        : getTotalExistingMortgageAmount(userData);
}

export function getMaintenance(userData: UserData) {
    return userData.household?.maintenance || 0;
}

export function getPrice(userData: UserData) {
    return userData.household?.price || 0;
}

export function getDownpayment(userData: UserData) {
    return userData.household?.downpayment || 0;
}

export function getFee(userData: UserData) {
    return userData.household?.fee || 0;
}

export function getPropertyTax(userData: UserData) {
    return userData.household?.propertyTax || 0;
}

export function getHousingPriceByCalculationType(userData: UserData): number {
    const calculationType = userData.household?.calculationType;

    if (calculationType === undefined) {
        return undefined;
    }

    return calculationType === MortgageCalculationType.new ? userData.household.price : userData.household.estimatedValue;
}
