// Tax table 32 for 2024
export default [
    { tt: 32, fr: 0, to: 2000, v: 0, r: 0 },
    { tt: 32, fr: 2001, to: 2100, v: 151, r: 0 },
    { tt: 32, fr: 2101, to: 2200, v: 153, r: 0 },
    { tt: 32, fr: 2201, to: 2300, v: 164, r: 0 },
    { tt: 32, fr: 2301, to: 2400, v: 174, r: 0 },
    { tt: 32, fr: 2401, to: 2500, v: 185, r: 0 },
    { tt: 32, fr: 2501, to: 2600, v: 195, r: 0 },
    { tt: 32, fr: 2601, to: 2700, v: 206, r: 0 },
    { tt: 32, fr: 2701, to: 2800, v: 216, r: 0 },
    { tt: 32, fr: 2801, to: 2900, v: 218, r: 0 },
    { tt: 32, fr: 2901, to: 3000, v: 229, r: 0 },
    { tt: 32, fr: 3001, to: 3100, v: 239, r: 0 },
    { tt: 32, fr: 3101, to: 3200, v: 250, r: 0 },
    { tt: 32, fr: 3201, to: 3300, v: 260, r: 0 },
    { tt: 32, fr: 3301, to: 3400, v: 271, r: 0 },
    { tt: 32, fr: 3401, to: 3500, v: 273, r: 0 },
    { tt: 32, fr: 3501, to: 3600, v: 284, r: 0 },
    { tt: 32, fr: 3601, to: 3700, v: 294, r: 0 },
    { tt: 32, fr: 3701, to: 3800, v: 305, r: 0 },
    { tt: 32, fr: 3801, to: 3900, v: 315, r: 0 },
    { tt: 32, fr: 3901, to: 4000, v: 325, r: 0 },
    { tt: 32, fr: 4001, to: 4100, v: 328, r: 0 },
    { tt: 32, fr: 4101, to: 4200, v: 338, r: 0 },
    { tt: 32, fr: 4201, to: 4300, v: 349, r: 0 },
    { tt: 32, fr: 4301, to: 4400, v: 359, r: 0 },
    { tt: 32, fr: 4401, to: 4500, v: 370, r: 0 },
    { tt: 32, fr: 4501, to: 4600, v: 380, r: 0 },
    { tt: 32, fr: 4601, to: 4700, v: 382, r: 0 },
    { tt: 32, fr: 4701, to: 4800, v: 392, r: 0 },
    { tt: 32, fr: 4801, to: 4900, v: 403, r: 0 },
    { tt: 32, fr: 4901, to: 5000, v: 413, r: 0 },
    { tt: 32, fr: 5001, to: 5100, v: 423, r: 0 },
    { tt: 32, fr: 5101, to: 5200, v: 433, r: 0 },
    { tt: 32, fr: 5201, to: 5300, v: 443, r: 0 },
    { tt: 32, fr: 5301, to: 5400, v: 445, r: 21 },
    { tt: 32, fr: 5401, to: 5500, v: 455, r: 46 },
    { tt: 32, fr: 5501, to: 5600, v: 465, r: 71 },
    { tt: 32, fr: 5601, to: 5700, v: 475, r: 96 },
    { tt: 32, fr: 5701, to: 5800, v: 485, r: 120 },
    { tt: 32, fr: 5801, to: 5900, v: 495, r: 145 },
    { tt: 32, fr: 5901, to: 6000, v: 497, r: 170 },
    { tt: 32, fr: 6001, to: 6100, v: 507, r: 195 },
    { tt: 32, fr: 6101, to: 6200, v: 517, r: 219 },
    { tt: 32, fr: 6201, to: 6300, v: 527, r: 241 },
    { tt: 32, fr: 6301, to: 6400, v: 537, r: 266 },
    { tt: 32, fr: 6401, to: 6500, v: 547, r: 291 },
    { tt: 32, fr: 6501, to: 6600, v: 549, r: 316 },
    { tt: 32, fr: 6601, to: 6700, v: 559, r: 340 },
    { tt: 32, fr: 6701, to: 6800, v: 569, r: 365 },
    { tt: 32, fr: 6801, to: 6900, v: 579, r: 390 },
    { tt: 32, fr: 6901, to: 7000, v: 590, r: 415 },
    { tt: 32, fr: 7001, to: 7100, v: 610, r: 439 },
    { tt: 32, fr: 7101, to: 7200, v: 630, r: 464 },
    { tt: 32, fr: 7201, to: 7300, v: 650, r: 489 },
    { tt: 32, fr: 7301, to: 7400, v: 670, r: 514 },
    { tt: 32, fr: 7401, to: 7500, v: 690, r: 536 },
    { tt: 32, fr: 7501, to: 7600, v: 710, r: 560 },
    { tt: 32, fr: 7601, to: 7700, v: 730, r: 585 },
    { tt: 32, fr: 7701, to: 7800, v: 750, r: 610 },
    { tt: 32, fr: 7801, to: 7900, v: 770, r: 635 },
    { tt: 32, fr: 7901, to: 8000, v: 790, r: 659 },
    { tt: 32, fr: 8001, to: 8100, v: 810, r: 684 },
    { tt: 32, fr: 8101, to: 8200, v: 830, r: 709 },
    { tt: 32, fr: 8201, to: 8300, v: 850, r: 734 },
    { tt: 32, fr: 8301, to: 8400, v: 870, r: 758 },
    { tt: 32, fr: 8401, to: 8500, v: 890, r: 783 },
    { tt: 32, fr: 8501, to: 8600, v: 910, r: 808 },
    { tt: 32, fr: 8601, to: 8700, v: 930, r: 830 },
    { tt: 32, fr: 8701, to: 8800, v: 950, r: 855 },
    { tt: 32, fr: 8801, to: 8900, v: 970, r: 879 },
    { tt: 32, fr: 8901, to: 9000, v: 990, r: 904 },
    { tt: 32, fr: 9001, to: 9100, v: 1010, r: 929 },
    { tt: 32, fr: 9101, to: 9200, v: 1030, r: 954 },
    { tt: 32, fr: 9201, to: 9300, v: 1050, r: 978 },
    { tt: 32, fr: 9301, to: 9400, v: 1070, r: 995 },
    { tt: 32, fr: 9401, to: 9500, v: 1090, r: 1017 },
    { tt: 32, fr: 9501, to: 9600, v: 1110, r: 1036 },
    { tt: 32, fr: 9601, to: 9700, v: 1130, r: 1055 },
    { tt: 32, fr: 9701, to: 9800, v: 1150, r: 1075 },
    { tt: 32, fr: 9801, to: 9900, v: 1170, r: 1097 },
    { tt: 32, fr: 9901, to: 10000, v: 1190, r: 1116 },
    { tt: 32, fr: 10001, to: 10100, v: 1210, r: 1134 },
    { tt: 32, fr: 10101, to: 10200, v: 1231, r: 1153 },
    { tt: 32, fr: 10201, to: 10300, v: 1250, r: 1175 },
    { tt: 32, fr: 10301, to: 10400, v: 1271, r: 1194 },
    { tt: 32, fr: 10401, to: 10500, v: 1291, r: 1212 },
    { tt: 32, fr: 10501, to: 10600, v: 1311, r: 1231 },
    { tt: 32, fr: 10601, to: 10700, v: 1331, r: 1253 },
    { tt: 32, fr: 10701, to: 10800, v: 1351, r: 1271 },
    { tt: 32, fr: 10801, to: 10900, v: 1371, r: 1290 },
    { tt: 32, fr: 10901, to: 11000, v: 1391, r: 1309 },
    { tt: 32, fr: 11001, to: 11100, v: 1411, r: 1331 },
    { tt: 32, fr: 11101, to: 11200, v: 1431, r: 1349 },
    { tt: 32, fr: 11201, to: 11300, v: 1451, r: 1368 },
    { tt: 32, fr: 11301, to: 11400, v: 1471, r: 1390 },
    { tt: 32, fr: 11401, to: 11500, v: 1491, r: 1409 },
    { tt: 32, fr: 11501, to: 11600, v: 1511, r: 1427 },
    { tt: 32, fr: 11601, to: 11700, v: 1531, r: 1446 },
    { tt: 32, fr: 11701, to: 11800, v: 1551, r: 1468 },
    { tt: 32, fr: 11801, to: 11900, v: 1571, r: 1486 },
    { tt: 32, fr: 11901, to: 12000, v: 1591, r: 1505 },
    { tt: 32, fr: 12001, to: 12100, v: 1611, r: 1524 },
    { tt: 32, fr: 12101, to: 12200, v: 1631, r: 1546 },
    { tt: 32, fr: 12201, to: 12300, v: 1651, r: 1564 },
    { tt: 32, fr: 12301, to: 12400, v: 1671, r: 1583 },
    { tt: 32, fr: 12401, to: 12500, v: 1691, r: 1602 },
    { tt: 32, fr: 12501, to: 12600, v: 1711, r: 1624 },
    { tt: 32, fr: 12601, to: 12700, v: 1731, r: 1642 },
    { tt: 32, fr: 12701, to: 12800, v: 1751, r: 1661 },
    { tt: 32, fr: 12801, to: 12900, v: 1771, r: 1680 },
    { tt: 32, fr: 12901, to: 13000, v: 1791, r: 1701 },
    { tt: 32, fr: 13001, to: 13100, v: 1811, r: 1720 },
    { tt: 32, fr: 13101, to: 13200, v: 1832, r: 1739 },
    { tt: 32, fr: 13201, to: 13300, v: 1852, r: 1761 },
    { tt: 32, fr: 13301, to: 13400, v: 1872, r: 1779 },
    { tt: 32, fr: 13401, to: 13500, v: 1893, r: 1798 },
    { tt: 32, fr: 13501, to: 13600, v: 1913, r: 1817 },
    { tt: 32, fr: 13601, to: 13700, v: 1933, r: 1839 },
    { tt: 32, fr: 13701, to: 13800, v: 1953, r: 1857 },
    { tt: 32, fr: 13801, to: 13900, v: 1973, r: 1876 },
    { tt: 32, fr: 13901, to: 14000, v: 1992, r: 1895 },
    { tt: 32, fr: 14001, to: 14100, v: 2012, r: 1916 },
    { tt: 32, fr: 14101, to: 14200, v: 2031, r: 1935 },
    { tt: 32, fr: 14201, to: 14300, v: 2050, r: 1954 },
    { tt: 32, fr: 14301, to: 14400, v: 2070, r: 1973 },
    { tt: 32, fr: 14401, to: 14500, v: 2089, r: 1994 },
    { tt: 32, fr: 14501, to: 14600, v: 2108, r: 2013 },
    { tt: 32, fr: 14601, to: 14700, v: 2128, r: 2032 },
    { tt: 32, fr: 14701, to: 14800, v: 2147, r: 2051 },
    { tt: 32, fr: 14801, to: 14900, v: 2166, r: 2072 },
    { tt: 32, fr: 14901, to: 15000, v: 2186, r: 2091 },
    { tt: 32, fr: 15001, to: 15100, v: 2205, r: 2110 },
    { tt: 32, fr: 15101, to: 15200, v: 2224, r: 2129 },
    { tt: 32, fr: 15201, to: 15300, v: 2244, r: 2150 },
    { tt: 32, fr: 15301, to: 15400, v: 2263, r: 2169 },
    { tt: 32, fr: 15401, to: 15500, v: 2284, r: 2191 },
    { tt: 32, fr: 15501, to: 15600, v: 2310, r: 2217 },
    { tt: 32, fr: 15601, to: 15700, v: 2336, r: 2244 },
    { tt: 32, fr: 15701, to: 15800, v: 2362, r: 2271 },
    { tt: 32, fr: 15801, to: 15900, v: 2389, r: 2298 },
    { tt: 32, fr: 15901, to: 16000, v: 2415, r: 2325 },
    { tt: 32, fr: 16001, to: 16100, v: 2441, r: 2352 },
    { tt: 32, fr: 16101, to: 16200, v: 2467, r: 2379 },
    { tt: 32, fr: 16201, to: 16300, v: 2493, r: 2406 },
    { tt: 32, fr: 16301, to: 16400, v: 2520, r: 2432 },
    { tt: 32, fr: 16401, to: 16500, v: 2546, r: 2459 },
    { tt: 32, fr: 16501, to: 16600, v: 2572, r: 2486 },
    { tt: 32, fr: 16601, to: 16700, v: 2598, r: 2513 },
    { tt: 32, fr: 16701, to: 16800, v: 2625, r: 2540 },
    { tt: 32, fr: 16801, to: 16900, v: 2651, r: 2567 },
    { tt: 32, fr: 16901, to: 17000, v: 2677, r: 2594 },
    { tt: 32, fr: 17001, to: 17100, v: 2703, r: 2618 },
    { tt: 32, fr: 17101, to: 17200, v: 2730, r: 2645 },
    { tt: 32, fr: 17201, to: 17300, v: 2756, r: 2672 },
    { tt: 32, fr: 17301, to: 17400, v: 2782, r: 2699 },
    { tt: 32, fr: 17401, to: 17500, v: 2808, r: 2725 },
    { tt: 32, fr: 17501, to: 17600, v: 2834, r: 2752 },
    { tt: 32, fr: 17601, to: 17700, v: 2861, r: 2779 },
    { tt: 32, fr: 17701, to: 17800, v: 2887, r: 2806 },
    { tt: 32, fr: 17801, to: 17900, v: 2913, r: 2833 },
    { tt: 32, fr: 17901, to: 18000, v: 2939, r: 2860 },
    { tt: 32, fr: 18001, to: 18100, v: 2966, r: 2887 },
    { tt: 32, fr: 18101, to: 18200, v: 2992, r: 2914 },
    { tt: 32, fr: 18201, to: 18300, v: 3018, r: 2940 },
    { tt: 32, fr: 18301, to: 18400, v: 3044, r: 2967 },
    { tt: 32, fr: 18401, to: 18500, v: 3070, r: 2994 },
    { tt: 32, fr: 18501, to: 18600, v: 3097, r: 3021 },
    { tt: 32, fr: 18601, to: 18700, v: 3123, r: 3048 },
    { tt: 32, fr: 18701, to: 18800, v: 3149, r: 3075 },
    { tt: 32, fr: 18801, to: 18900, v: 3175, r: 3102 },
    { tt: 32, fr: 18901, to: 19000, v: 3202, r: 3129 },
    { tt: 32, fr: 19001, to: 19100, v: 3228, r: 3155 },
    { tt: 32, fr: 19101, to: 19200, v: 3254, r: 3182 },
    { tt: 32, fr: 19201, to: 19300, v: 3280, r: 3209 },
    { tt: 32, fr: 19301, to: 19400, v: 3306, r: 3236 },
    { tt: 32, fr: 19401, to: 19500, v: 3333, r: 3263 },
    { tt: 32, fr: 19501, to: 19600, v: 3359, r: 3290 },
    { tt: 32, fr: 19601, to: 19700, v: 3385, r: 3316 },
    { tt: 32, fr: 19701, to: 19800, v: 3411, r: 3342 },
    { tt: 32, fr: 19801, to: 19900, v: 3438, r: 3368 },
    { tt: 32, fr: 19901, to: 20000, v: 3464, r: 3394 },
    { tt: 32, fr: 20001, to: 20200, v: 3516, r: 3446 },
    { tt: 32, fr: 20201, to: 20400, v: 3569, r: 3496 },
    { tt: 32, fr: 20401, to: 20600, v: 3621, r: 3548 },
    { tt: 32, fr: 20601, to: 20800, v: 3674, r: 3600 },
    { tt: 32, fr: 20801, to: 21000, v: 3726, r: 3652 },
    { tt: 32, fr: 21001, to: 21200, v: 3778, r: 3704 },
    { tt: 32, fr: 21201, to: 21400, v: 3831, r: 3756 },
    { tt: 32, fr: 21401, to: 21600, v: 3883, r: 3808 },
    { tt: 32, fr: 21601, to: 21800, v: 3936, r: 3860 },
    { tt: 32, fr: 21801, to: 22000, v: 3988, r: 3913 },
    { tt: 32, fr: 22001, to: 22200, v: 4041, r: 3965 },
    { tt: 32, fr: 22201, to: 22400, v: 4093, r: 4017 },
    { tt: 32, fr: 22401, to: 22600, v: 4146, r: 4069 },
    { tt: 32, fr: 22601, to: 22800, v: 4198, r: 4121 },
    { tt: 32, fr: 22801, to: 23000, v: 4251, r: 4173 },
    { tt: 32, fr: 23001, to: 23200, v: 4306, r: 4225 },
    { tt: 32, fr: 23201, to: 23400, v: 4360, r: 4277 },
    { tt: 32, fr: 23401, to: 23600, v: 4414, r: 4327 },
    { tt: 32, fr: 23601, to: 23800, v: 4468, r: 4379 },
    { tt: 32, fr: 23801, to: 24000, v: 4522, r: 4428 },
    { tt: 32, fr: 24001, to: 24200, v: 4576, r: 4483 },
    { tt: 32, fr: 24201, to: 24400, v: 4630, r: 4535 },
    { tt: 32, fr: 24401, to: 24600, v: 4684, r: 4590 },
    { tt: 32, fr: 24601, to: 24800, v: 4738, r: 4642 },
    { tt: 32, fr: 24801, to: 25000, v: 4792, r: 4694 },
    { tt: 32, fr: 25001, to: 25200, v: 4847, r: 4749 },
    { tt: 32, fr: 25201, to: 25400, v: 4901, r: 4801 },
    { tt: 32, fr: 25401, to: 25600, v: 4955, r: 4853 },
    { tt: 32, fr: 25601, to: 25800, v: 5009, r: 4907 },
    { tt: 32, fr: 25801, to: 26000, v: 5063, r: 4959 },
    { tt: 32, fr: 26001, to: 26200, v: 5117, r: 5011 },
    { tt: 32, fr: 26201, to: 26400, v: 5171, r: 5066 },
    { tt: 32, fr: 26401, to: 26600, v: 5225, r: 5118 },
    { tt: 32, fr: 26601, to: 26800, v: 5279, r: 5173 },
    { tt: 32, fr: 26801, to: 27000, v: 5333, r: 5225 },
    { tt: 32, fr: 27001, to: 27200, v: 5388, r: 5277 },
    { tt: 32, fr: 27201, to: 27400, v: 5442, r: 5332 },
    { tt: 32, fr: 27401, to: 27600, v: 5496, r: 5384 },
    { tt: 32, fr: 27601, to: 27800, v: 5550, r: 5436 },
    { tt: 32, fr: 27801, to: 28000, v: 5604, r: 5491 },
    { tt: 32, fr: 28001, to: 28200, v: 5658, r: 5543 },
    { tt: 32, fr: 28201, to: 28400, v: 5712, r: 5597 },
    { tt: 32, fr: 28401, to: 28600, v: 5766, r: 5650 },
    { tt: 32, fr: 28601, to: 28800, v: 5820, r: 5702 },
    { tt: 32, fr: 28801, to: 29000, v: 5874, r: 5756 },
    { tt: 32, fr: 29001, to: 29200, v: 5928, r: 5808 },
    { tt: 32, fr: 29201, to: 29400, v: 5983, r: 5860 },
    { tt: 32, fr: 29401, to: 29600, v: 6037, r: 5915 },
    { tt: 32, fr: 29601, to: 29800, v: 6091, r: 5967 },
    { tt: 32, fr: 29801, to: 30000, v: 6145, r: 6022 },
    { tt: 32, fr: 30001, to: 30200, v: 6199, r: 6074 },
    { tt: 32, fr: 30201, to: 30400, v: 6253, r: 6126 },
    { tt: 32, fr: 30401, to: 30600, v: 6307, r: 6181 },
    { tt: 32, fr: 30601, to: 30800, v: 6361, r: 6233 },
    { tt: 32, fr: 30801, to: 31000, v: 6415, r: 6285 },
    { tt: 32, fr: 31001, to: 31200, v: 6469, r: 6340 },
    { tt: 32, fr: 31201, to: 31400, v: 6524, r: 6392 },
    { tt: 32, fr: 31401, to: 31600, v: 6578, r: 6445 },
    { tt: 32, fr: 31601, to: 31800, v: 6632, r: 6501 },
    { tt: 32, fr: 31801, to: 32000, v: 6686, r: 6555 },
    { tt: 32, fr: 32001, to: 32200, v: 6740, r: 6611 },
    { tt: 32, fr: 32201, to: 32400, v: 6794, r: 6664 },
    { tt: 32, fr: 32401, to: 32600, v: 6848, r: 6717 },
    { tt: 32, fr: 32601, to: 32800, v: 6902, r: 6773 },
    { tt: 32, fr: 32801, to: 33000, v: 6956, r: 6827 },
    { tt: 32, fr: 33001, to: 33200, v: 7010, r: 6880 },
    { tt: 32, fr: 33201, to: 33400, v: 7065, r: 6936 },
    { tt: 32, fr: 33401, to: 33600, v: 7119, r: 6989 },
    { tt: 32, fr: 33601, to: 33800, v: 7173, r: 7045 },
    { tt: 32, fr: 33801, to: 34000, v: 7227, r: 7099 },
    { tt: 32, fr: 34001, to: 34200, v: 7281, r: 7152 },
    { tt: 32, fr: 34201, to: 34400, v: 7335, r: 7208 },
    { tt: 32, fr: 34401, to: 34600, v: 7389, r: 7261 },
    { tt: 32, fr: 34601, to: 34800, v: 7443, r: 7315 },
    { tt: 32, fr: 34801, to: 35000, v: 7497, r: 7371 },
    { tt: 32, fr: 35001, to: 35200, v: 7551, r: 7424 },
    { tt: 32, fr: 35201, to: 35400, v: 7606, r: 7477 },
    { tt: 32, fr: 35401, to: 35600, v: 7660, r: 7533 },
    { tt: 32, fr: 35601, to: 35800, v: 7714, r: 7587 },
    { tt: 32, fr: 35801, to: 36000, v: 7768, r: 7643 },
    { tt: 32, fr: 36001, to: 36200, v: 7822, r: 7696 },
    { tt: 32, fr: 36201, to: 36400, v: 7876, r: 7749 },
    { tt: 32, fr: 36401, to: 36600, v: 7930, r: 7805 },
    { tt: 32, fr: 36601, to: 36800, v: 7984, r: 7859 },
    { tt: 32, fr: 36801, to: 37000, v: 8038, r: 7912 },
    { tt: 32, fr: 37001, to: 37200, v: 8092, r: 7968 },
    { tt: 32, fr: 37201, to: 37400, v: 8147, r: 8021 },
    { tt: 32, fr: 37401, to: 37600, v: 8201, r: 8077 },
    { tt: 32, fr: 37601, to: 37800, v: 8255, r: 8128 },
    { tt: 32, fr: 37801, to: 38000, v: 8308, r: 8181 },
    { tt: 32, fr: 38001, to: 38200, v: 8362, r: 8235 },
    { tt: 32, fr: 38201, to: 38400, v: 8416, r: 8291 },
    { tt: 32, fr: 38401, to: 38600, v: 8471, r: 8344 },
    { tt: 32, fr: 38601, to: 38800, v: 8535, r: 8408 },
    { tt: 32, fr: 38801, to: 39000, v: 8599, r: 8472 },
    { tt: 32, fr: 39001, to: 39200, v: 8663, r: 8536 },
    { tt: 32, fr: 39201, to: 39400, v: 8727, r: 8600 },
    { tt: 32, fr: 39401, to: 39600, v: 8791, r: 8664 },
    { tt: 32, fr: 39601, to: 39800, v: 8855, r: 8728 },
    { tt: 32, fr: 39801, to: 40000, v: 8919, r: 8792 },
    { tt: 32, fr: 40001, to: 40200, v: 8983, r: 8856 },
    { tt: 32, fr: 40201, to: 40400, v: 9047, r: 8920 },
    { tt: 32, fr: 40401, to: 40600, v: 9111, r: 8984 },
    { tt: 32, fr: 40601, to: 40800, v: 9175, r: 9048 },
    { tt: 32, fr: 40801, to: 41000, v: 9239, r: 9112 },
    { tt: 32, fr: 41001, to: 41200, v: 9303, r: 9176 },
    { tt: 32, fr: 41201, to: 41400, v: 9367, r: 9240 },
    { tt: 32, fr: 41401, to: 41600, v: 9431, r: 9304 },
    { tt: 32, fr: 41601, to: 41800, v: 9495, r: 9368 },
    { tt: 32, fr: 41801, to: 42000, v: 9559, r: 9432 },
    { tt: 32, fr: 42001, to: 42200, v: 9623, r: 9496 },
    { tt: 32, fr: 42201, to: 42400, v: 9687, r: 9560 },
    { tt: 32, fr: 42401, to: 42600, v: 9751, r: 9624 },
    { tt: 32, fr: 42601, to: 42800, v: 9815, r: 9688 },
    { tt: 32, fr: 42801, to: 43000, v: 9879, r: 9752 },
    { tt: 32, fr: 43001, to: 43200, v: 9943, r: 9816 },
    { tt: 32, fr: 43201, to: 43400, v: 10007, r: 9880 },
    { tt: 32, fr: 43401, to: 43600, v: 10071, r: 9944 },
    { tt: 32, fr: 43601, to: 43800, v: 10135, r: 10008 },
    { tt: 32, fr: 43801, to: 44000, v: 10199, r: 10072 },
    { tt: 32, fr: 44001, to: 44200, v: 10263, r: 10136 },
    { tt: 32, fr: 44201, to: 44400, v: 10327, r: 10200 },
    { tt: 32, fr: 44401, to: 44600, v: 10391, r: 10264 },
    { tt: 32, fr: 44601, to: 44800, v: 10455, r: 10328 },
    { tt: 32, fr: 44801, to: 45000, v: 10519, r: 10392 },
    { tt: 32, fr: 45001, to: 45200, v: 10583, r: 10456 },
    { tt: 32, fr: 45201, to: 45400, v: 10647, r: 10520 },
    { tt: 32, fr: 45401, to: 45600, v: 10711, r: 10584 },
    { tt: 32, fr: 45601, to: 45800, v: 10775, r: 10648 },
    { tt: 32, fr: 45801, to: 46000, v: 10839, r: 10712 },
    { tt: 32, fr: 46001, to: 46200, v: 10903, r: 10776 },
    { tt: 32, fr: 46201, to: 46400, v: 10967, r: 10840 },
    { tt: 32, fr: 46401, to: 46600, v: 11031, r: 10904 },
    { tt: 32, fr: 46601, to: 46800, v: 11095, r: 10968 },
    { tt: 32, fr: 46801, to: 47000, v: 11159, r: 11032 },
    { tt: 32, fr: 47001, to: 47200, v: 11223, r: 11096 },
    { tt: 32, fr: 47201, to: 47400, v: 11287, r: 11160 },
    { tt: 32, fr: 47401, to: 47600, v: 11351, r: 11224 },
    { tt: 32, fr: 47601, to: 47800, v: 11415, r: 11288 },
    { tt: 32, fr: 47801, to: 48000, v: 11479, r: 11352 },
    { tt: 32, fr: 48001, to: 48200, v: 11543, r: 11416 },
    { tt: 32, fr: 48201, to: 48400, v: 11607, r: 11480 },
    { tt: 32, fr: 48401, to: 48600, v: 11671, r: 11544 },
    { tt: 32, fr: 48601, to: 48800, v: 11735, r: 11608 },
    { tt: 32, fr: 48801, to: 49000, v: 11799, r: 11672 },
    { tt: 32, fr: 49001, to: 49200, v: 11863, r: 11736 },
    { tt: 32, fr: 49201, to: 49400, v: 11927, r: 11800 },
    { tt: 32, fr: 49401, to: 49600, v: 11991, r: 11864 },
    { tt: 32, fr: 49601, to: 49800, v: 12055, r: 11928 },
    { tt: 32, fr: 49801, to: 50000, v: 12119, r: 11992 },
    { tt: 32, fr: 50001, to: 50200, v: 12183, r: 12056 },
    { tt: 32, fr: 50201, to: 50400, v: 12247, r: 12120 },
    { tt: 32, fr: 50401, to: 50600, v: 12311, r: 12184 },
    { tt: 32, fr: 50601, to: 50800, v: 12375, r: 12248 },
    { tt: 32, fr: 50801, to: 51000, v: 12439, r: 12312 },
    { tt: 32, fr: 51001, to: 51200, v: 12503, r: 12376 },
    { tt: 32, fr: 51201, to: 51400, v: 12592, r: 12464 },
    { tt: 32, fr: 51401, to: 51600, v: 12696, r: 12568 },
    { tt: 32, fr: 51601, to: 51800, v: 12800, r: 12672 },
    { tt: 32, fr: 51801, to: 52000, v: 12904, r: 12773 },
    { tt: 32, fr: 52001, to: 52200, v: 13008, r: 12877 },
    { tt: 32, fr: 52201, to: 52400, v: 13112, r: 12981 },
    { tt: 32, fr: 52401, to: 52600, v: 13216, r: 13085 },
    { tt: 32, fr: 52601, to: 52800, v: 13320, r: 13189 },
    { tt: 32, fr: 52801, to: 53000, v: 13424, r: 13293 },
    { tt: 32, fr: 53001, to: 53200, v: 13528, r: 13397 },
    { tt: 32, fr: 53201, to: 53400, v: 13632, r: 13501 },
    { tt: 32, fr: 53401, to: 53600, v: 13736, r: 13603 },
    { tt: 32, fr: 53601, to: 53800, v: 13840, r: 13707 },
    { tt: 32, fr: 53801, to: 54000, v: 13944, r: 13811 },
    { tt: 32, fr: 54001, to: 54200, v: 14048, r: 13915 },
    { tt: 32, fr: 54201, to: 54400, v: 14152, r: 14019 },
    { tt: 32, fr: 54401, to: 54600, v: 14256, r: 14123 },
    { tt: 32, fr: 54601, to: 54800, v: 14360, r: 14227 },
    { tt: 32, fr: 54801, to: 55000, v: 14464, r: 14331 },
    { tt: 32, fr: 55001, to: 55200, v: 14568, r: 14435 },
    { tt: 32, fr: 55201, to: 55400, v: 14672, r: 14536 },
    { tt: 32, fr: 55401, to: 55600, v: 14776, r: 14640 },
    { tt: 32, fr: 55601, to: 55800, v: 14880, r: 14744 },
    { tt: 32, fr: 55801, to: 56000, v: 14984, r: 14848 },
    { tt: 32, fr: 56001, to: 56200, v: 15088, r: 14952 },
    { tt: 32, fr: 56201, to: 56400, v: 15192, r: 15056 },
    { tt: 32, fr: 56401, to: 56600, v: 15296, r: 15160 },
    { tt: 32, fr: 56601, to: 56800, v: 15400, r: 15264 },
    { tt: 32, fr: 56801, to: 57000, v: 15504, r: 15365 },
    { tt: 32, fr: 57001, to: 57200, v: 15608, r: 15469 },
    { tt: 32, fr: 57201, to: 57400, v: 15712, r: 15573 },
    { tt: 32, fr: 57401, to: 57600, v: 15816, r: 15677 },
    { tt: 32, fr: 57601, to: 57800, v: 15920, r: 15781 },
    { tt: 32, fr: 57801, to: 58000, v: 16024, r: 15885 },
    { tt: 32, fr: 58001, to: 58200, v: 16128, r: 15984 },
    { tt: 32, fr: 58201, to: 58400, v: 16232, r: 16088 },
    { tt: 32, fr: 58401, to: 58600, v: 16336, r: 16192 },
    { tt: 32, fr: 58601, to: 58800, v: 16440, r: 16296 },
    { tt: 32, fr: 58801, to: 59000, v: 16544, r: 16400 },
    { tt: 32, fr: 59001, to: 59200, v: 16648, r: 16504 },
    { tt: 32, fr: 59201, to: 59400, v: 16752, r: 16608 },
    { tt: 32, fr: 59401, to: 59600, v: 16856, r: 16712 },
    { tt: 32, fr: 59601, to: 59800, v: 16960, r: 16816 },
    { tt: 32, fr: 59801, to: 60000, v: 17064, r: 16920 },
    { tt: 32, fr: 60001, to: 60200, v: 17168, r: 17024 },
    { tt: 32, fr: 60201, to: 60400, v: 17272, r: 17128 },
    { tt: 32, fr: 60401, to: 60600, v: 17376, r: 17232 },
    { tt: 32, fr: 60601, to: 60800, v: 17480, r: 17336 },
    { tt: 32, fr: 60801, to: 61000, v: 17584, r: 17440 },
    { tt: 32, fr: 61001, to: 61200, v: 17688, r: 17544 },
    { tt: 32, fr: 61201, to: 61400, v: 17792, r: 17648 },
    { tt: 32, fr: 61401, to: 61600, v: 17896, r: 17752 },
    { tt: 32, fr: 61601, to: 61800, v: 18000, r: 17856 },
    { tt: 32, fr: 61801, to: 62000, v: 18104, r: 17960 },
    { tt: 32, fr: 62001, to: 62200, v: 18208, r: 18064 },
    { tt: 32, fr: 62201, to: 62400, v: 18312, r: 18168 },
    { tt: 32, fr: 62401, to: 62600, v: 18416, r: 18272 },
    { tt: 32, fr: 62601, to: 62800, v: 18520, r: 18376 },
    { tt: 32, fr: 62801, to: 63000, v: 18624, r: 18480 },
    { tt: 32, fr: 63001, to: 63200, v: 18728, r: 18584 },
    { tt: 32, fr: 63201, to: 63400, v: 18832, r: 18688 },
    { tt: 32, fr: 63401, to: 63600, v: 18936, r: 18792 },
    { tt: 32, fr: 63601, to: 63800, v: 19040, r: 18896 },
    { tt: 32, fr: 63801, to: 64000, v: 19144, r: 19000 },
    { tt: 32, fr: 64001, to: 64200, v: 19248, r: 19104 },
    { tt: 32, fr: 64201, to: 64400, v: 19352, r: 19208 },
    { tt: 32, fr: 64401, to: 64600, v: 19456, r: 19312 },
    { tt: 32, fr: 64601, to: 64800, v: 19565, r: 19424 },
    { tt: 32, fr: 64801, to: 65000, v: 19675, r: 19533 },
    { tt: 32, fr: 65001, to: 65200, v: 19785, r: 19645 },
    { tt: 32, fr: 65201, to: 65400, v: 19895, r: 19754 },
    { tt: 32, fr: 65401, to: 65600, v: 20005, r: 19862 },
    { tt: 32, fr: 65601, to: 65800, v: 20115, r: 19975 },
    { tt: 32, fr: 65801, to: 66000, v: 20225, r: 20083 },
    { tt: 32, fr: 66001, to: 66200, v: 20335, r: 20196 },
    { tt: 32, fr: 66201, to: 66400, v: 20445, r: 20304 },
    { tt: 32, fr: 66401, to: 66600, v: 20555, r: 20412 },
    { tt: 32, fr: 66601, to: 66800, v: 20665, r: 20525 },
    { tt: 32, fr: 66801, to: 67000, v: 20775, r: 20633 },
    { tt: 32, fr: 67001, to: 67200, v: 20885, r: 20742 },
    { tt: 32, fr: 67201, to: 67400, v: 20995, r: 20854 },
    { tt: 32, fr: 67401, to: 67600, v: 21105, r: 20963 },
    { tt: 32, fr: 67601, to: 67800, v: 21215, r: 21075 },
    { tt: 32, fr: 67801, to: 68000, v: 21325, r: 21184 },
    { tt: 32, fr: 68001, to: 68200, v: 21435, r: 21292 },
    { tt: 32, fr: 68201, to: 68400, v: 21545, r: 21405 },
    { tt: 32, fr: 68401, to: 68600, v: 21655, r: 21513 },
    { tt: 32, fr: 68601, to: 68800, v: 21765, r: 21621 },
    { tt: 32, fr: 68801, to: 69000, v: 21875, r: 21734 },
    { tt: 32, fr: 69001, to: 69200, v: 21985, r: 21842 },
    { tt: 32, fr: 69201, to: 69400, v: 22095, r: 21955 },
    { tt: 32, fr: 69401, to: 69600, v: 22205, r: 22063 },
    { tt: 32, fr: 69601, to: 69800, v: 22315, r: 22172 },
    { tt: 32, fr: 69801, to: 70000, v: 22425, r: 22284 },
    { tt: 32, fr: 70001, to: 70200, v: 22535, r: 22393 },
    { tt: 32, fr: 70201, to: 70400, v: 22645, r: 22501 },
    { tt: 32, fr: 70401, to: 70600, v: 22755, r: 22614 },
    { tt: 32, fr: 70601, to: 70800, v: 22865, r: 22722 },
    { tt: 32, fr: 70801, to: 71000, v: 22975, r: 22835 },
    { tt: 32, fr: 71001, to: 71200, v: 23085, r: 22943 },
    { tt: 32, fr: 71201, to: 71400, v: 23195, r: 23051 },
    { tt: 32, fr: 71401, to: 71600, v: 23305, r: 23164 },
    { tt: 32, fr: 71601, to: 71800, v: 23415, r: 23272 },
    { tt: 32, fr: 71801, to: 72000, v: 23525, r: 23385 },
    { tt: 32, fr: 72001, to: 72200, v: 23635, r: 23493 },
    { tt: 32, fr: 72201, to: 72400, v: 23745, r: 23602 },
    { tt: 32, fr: 72401, to: 72600, v: 23855, r: 23714 },
    { tt: 32, fr: 72601, to: 72800, v: 23965, r: 23823 },
    { tt: 32, fr: 72801, to: 73000, v: 24075, r: 23931 },
    { tt: 32, fr: 73001, to: 73200, v: 24185, r: 24044 },
    { tt: 32, fr: 73201, to: 73400, v: 24295, r: 24152 },
    { tt: 32, fr: 73401, to: 73600, v: 24405, r: 24265 },
    { tt: 32, fr: 73601, to: 73800, v: 24515, r: 24373 },
    { tt: 32, fr: 73801, to: 74000, v: 24625, r: 24481 },
    { tt: 32, fr: 74001, to: 74200, v: 24735, r: 24594 },
    { tt: 32, fr: 74201, to: 74400, v: 24845, r: 24702 },
    { tt: 32, fr: 74401, to: 74600, v: 24955, r: 24811 },
    { tt: 32, fr: 74601, to: 74800, v: 25065, r: 24923 },
    { tt: 32, fr: 74801, to: 75000, v: 25175, r: 25032 },
    { tt: 32, fr: 75001, to: 75200, v: 25285, r: 25144 },
    { tt: 32, fr: 75201, to: 75400, v: 25395, r: 25253 },
    { tt: 32, fr: 75401, to: 75600, v: 25505, r: 25361 },
    { tt: 32, fr: 75601, to: 75800, v: 25615, r: 25474 },
    { tt: 32, fr: 75801, to: 76000, v: 25725, r: 25582 },
    { tt: 32, fr: 76001, to: 76200, v: 25835, r: 25690 },
    { tt: 32, fr: 76201, to: 76400, v: 25945, r: 25803 },
    { tt: 32, fr: 76401, to: 76600, v: 26055, r: 25911 },
    { tt: 32, fr: 76601, to: 76800, v: 26165, r: 26024 },
    { tt: 32, fr: 76801, to: 77000, v: 26275, r: 26132 },
    { tt: 32, fr: 77001, to: 77200, v: 26385, r: 26241 },
    { tt: 32, fr: 77201, to: 77400, v: 26495, r: 26353 },
    { tt: 32, fr: 77401, to: 77600, v: 26605, r: 26462 },
    { tt: 32, fr: 77601, to: 77800, v: 26715, r: 26570 },
    { tt: 32, fr: 77801, to: 78000, v: 26825, r: 26683 },
    { tt: 32, fr: 78001, to: 78200, v: 26935, r: 26791 },
    { tt: 32, fr: 78201, to: 78400, v: 27045, r: 26904 },
    { tt: 32, fr: 78401, to: 78600, v: 27155, r: 27012 },
    { tt: 32, fr: 78601, to: 78800, v: 27265, r: 27120 },
    { tt: 32, fr: 78801, to: 79000, v: 27375, r: 27233 },
    { tt: 32, fr: 79001, to: 79200, v: 27485, r: 27341 },
    { tt: 32, fr: 79201, to: 79400, v: 27595, r: 27450 },
    { tt: 32, fr: 79401, to: 79600, v: 27705, r: 27562 },
    { tt: 32, fr: 79601, to: 79800, v: 27815, r: 27671 },
    { tt: 32, fr: 79801, to: 80000, v: 27925, r: 27783 },
    { tt: 32, fr: 80001, to: 81200, v: 35, r: 35 },
    { tt: 32, fr: 81201, to: 82400, v: 35, r: 35 },
    { tt: 32, fr: 82401, to: 83000, v: 36, r: 35 },
    { tt: 32, fr: 83001, to: 83600, v: 36, r: 36 },
    { tt: 32, fr: 83601, to: 84400, v: 36, r: 36 },
    { tt: 32, fr: 84401, to: 85800, v: 36, r: 36 },
    { tt: 32, fr: 85801, to: 86800, v: 36, r: 36 },
    { tt: 32, fr: 86801, to: 87600, v: 37, r: 36 },
    { tt: 32, fr: 87601, to: 88000, v: 37, r: 37 },
    { tt: 32, fr: 88001, to: 89800, v: 37, r: 37 },
    { tt: 32, fr: 89801, to: 91800, v: 37, r: 37 },
    { tt: 32, fr: 91801, to: 92000, v: 38, r: 37 },
    { tt: 32, fr: 92001, to: 92600, v: 38, r: 37 },
    { tt: 32, fr: 92601, to: 93200, v: 38, r: 38 },
    { tt: 32, fr: 93201, to: 96200, v: 38, r: 38 },
    { tt: 32, fr: 96201, to: 97000, v: 38, r: 38 },
    { tt: 32, fr: 97001, to: 97400, v: 38, r: 38 },
    { tt: 32, fr: 97401, to: 98200, v: 39, r: 38 },
    { tt: 32, fr: 98201, to: 101000, v: 39, r: 39 },
    { tt: 32, fr: 101001, to: 102000, v: 39, r: 39 },
    { tt: 32, fr: 102001, to: 103600, v: 39, r: 39 },
    { tt: 32, fr: 103601, to: 104600, v: 40, r: 39 },
    { tt: 32, fr: 104601, to: 105400, v: 40, r: 40 },
    { tt: 32, fr: 105401, to: 106200, v: 40, r: 40 },
    { tt: 32, fr: 106201, to: 110800, v: 40, r: 40 },
    { tt: 32, fr: 110801, to: 111800, v: 41, r: 40 },
    { tt: 32, fr: 111801, to: 112000, v: 41, r: 41 },
    { tt: 32, fr: 112001, to: 112800, v: 41, r: 41 },
    { tt: 32, fr: 112801, to: 115400, v: 41, r: 41 },
    { tt: 32, fr: 115401, to: 118400, v: 41, r: 41 },
    { tt: 32, fr: 118401, to: 119000, v: 41, r: 41 },
    { tt: 32, fr: 119001, to: 120000, v: 42, r: 41 },
    { tt: 32, fr: 120001, to: 125600, v: 42, r: 42 },
    { tt: 32, fr: 125601, to: 126000, v: 42, r: 42 },
    { tt: 32, fr: 126001, to: 127600, v: 42, r: 42 },
    { tt: 32, fr: 127601, to: 128400, v: 42, r: 42 },
    { tt: 32, fr: 128401, to: 129600, v: 43, r: 42 },
    { tt: 32, fr: 129601, to: 133600, v: 43, r: 43 },
    { tt: 32, fr: 133601, to: 139600, v: 43, r: 43 },
    { tt: 32, fr: 139601, to: 141000, v: 44, r: 43 },
    { tt: 32, fr: 141001, to: 142400, v: 44, r: 44 },
    { tt: 32, fr: 142401, to: 142800, v: 44, r: 44 },
    { tt: 32, fr: 142801, to: 143000, v: 44, r: 44 },
    { tt: 32, fr: 143001, to: 153000, v: 44, r: 44 },
    { tt: 32, fr: 153001, to: 154400, v: 45, r: 44 },
    { tt: 32, fr: 154401, to: 161400, v: 45, r: 45 },
    { tt: 32, fr: 161401, to: 165000, v: 45, r: 45 },
    { tt: 32, fr: 165001, to: 169200, v: 45, r: 45 },
    { tt: 32, fr: 169201, to: 170800, v: 46, r: 45 },
    { tt: 32, fr: 170801, to: 186000, v: 46, r: 46 },
    { tt: 32, fr: 186001, to: 195000, v: 46, r: 46 },
    { tt: 32, fr: 195001, to: 219400, v: 47, r: 47 },
    { tt: 32, fr: 219401, to: 238200, v: 47, r: 47 },
    { tt: 32, fr: 238201, to: 267800, v: 48, r: 48 },
    { tt: 32, fr: 267801, to: 306400, v: 48, r: 48 },
    { tt: 32, fr: 306401, to: 343000, v: 49, r: 49 },
    { tt: 32, fr: 343001, to: 429000, v: 49, r: 49 },
    { tt: 32, fr: 429001, to: 477600, v: 50, r: 50 },
    { tt: 32, fr: 477601, to: 715000, v: 50, r: 50 },
    { tt: 32, fr: 715001, to: 785200, v: 51, r: 51 },
    { tt: 32, fr: 785201, to: Number.MAX_SAFE_INTEGER, v: 51, r: 51 },
];
