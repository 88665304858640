import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setChildAge, setChildHouseholdRate } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import RadioComponent from "../../form_controls/radio_component";
import { useTranslation } from "react-i18next";
import { ChildHouseholdRate } from "../../../models/user_data";
import OverlineComponent from "../../typography/overline_component";

export default function KalpChildStepComponent(props) {
    const { age, householdRate } = useSelector((state: IRootState) => {
        return {
            age: state.userEditingData.kalp.children[props.index].age,
            householdRate: state.userEditingData.kalp.children[props.index].householdRate,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAge(value: number) {
        props.onValidate(value < 20);
        dispatch(setChildAge({ index: props.index, value }));
    }

    function onChangeHouseholdRate(value: ChildHouseholdRate) {
        props.onValidate(true);
        dispatch(setChildHouseholdRate({ index: props.index, value }));
    }

    React.useEffect(() => {
        props.onValidate(age < 20);
    });

    const childOptions = [
        { label: "50 %", value: ChildHouseholdRate.HALF_TIME },
        { label: "100 %", value: ChildHouseholdRate.FULL_TIME },
    ];

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("kalp:child-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                name={"kalp_child_" + props.index + "_age"}
                unit={t("common:years")}
                onChange={onChangeAge}
                min={0}
                step={1}
                defaultMax={19}
                label={t("kalp:input-child-age")}
                value={age}
            ></NumberComponent>
            <RadioComponent
                label={t("kalp:input-child-percentage")}
                small={true}
                options={childOptions}
                value={householdRate}
                onChange={onChangeHouseholdRate}
            ></RadioComponent>
        </IndataStepComponent>
    );
}
