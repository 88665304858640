import * as React from "react";

import InsightsChart from "./insights_chart";
import { IHousingResult, IResult } from "../../models/result";
import { IInfoTooltipProps } from "./chart";
import { TooltipEvent } from "../../utility/analytics";

export interface IHousingChartProps {
    result: IHousingResult;
    period: number;
    infoTooltips?: Array<IInfoTooltipProps>;
    onEngagement: (label: TooltipEvent) => void;
}

export class HousingChart extends React.Component<IHousingChartProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { period, result, infoTooltips, onEngagement } = this.props;

        if (!result) {
            return;
        }

        // This is setup in a funky way to keep the properties in the same order as in the input "table"
        const data: IResult = {};
        if (result.fee) {
            data.fee = result.fee;
        }
        if (result.propertyTax) {
            data.propertyTax = result.propertyTax;
        }
        data.maintenance = result.maintenance;
        data.interest = result.interest;
        data.amortization = result.amortization;

        return (
            <InsightsChart
                id="housing"
                period={period}
                summary={result.summary}
                data={data}
                infoTooltips={infoTooltips}
                className="housing"
                onEngagement={onEngagement}
            ></InsightsChart>
        );
    }
}
