import { CustomOfferConfig } from "components/offers/custom_offer";
import { DefaultOfferConfig } from "components/offers/default_offer";
import { DisclaimerOfferConfig } from "components/offers/disclaimer_offer";
import { InterestOfferConfig } from "components/offers/interest_offer";
import { MoveLoanOfferConfig } from "components/offers/moveloan_offer";
import { NewLoanOfferConfig } from "components/offers/newloan_offer";
import { IScenarioData } from "../reducers/rootReducer";
import { IUserDataInterface } from "../user_data_interface";

export enum OfferModules {
    default = "default",
    interest = "interest",
    moveloan = "moveloan",
    disclaimer = "disclaimer",
    custom = "custom",
    newloan = "newloan",
}

export interface IOfferConfig<T> {
    show: boolean;
    config?: T;
}

export type Show<T> = (section: string, scenarioData: IScenarioData, userData: IUserDataInterface) => IOfferConfig<T>;

export interface DefaultOffer {
    module: OfferModules.default;
    show: Show<DefaultOfferConfig>;
}

export interface CustomOffer {
    module: OfferModules.custom;
    show: Show<CustomOfferConfig>;
}

export interface InterestOffer {
    module: OfferModules.interest;
    show: Show<InterestOfferConfig>;
}

export interface MoveLoanOffer {
    module: OfferModules.moveloan;
    show: Show<MoveLoanOfferConfig>;
}

export interface DisclaimerOffer {
    module: OfferModules.disclaimer;
    show: Show<DisclaimerOfferConfig>;
}

export interface InterestOffer {
    module: OfferModules.interest;
    show: Show<InterestOfferConfig>;
}

export interface NewLoanOffer {
    module: OfferModules.newloan;
    show: Show<NewLoanOfferConfig>;
}

export type Offers = DefaultOffer | CustomOffer | InterestOffer | MoveLoanOffer | DisclaimerOffer | InterestOffer | NewLoanOffer;

export type OfferConfigs =
    | DefaultOfferConfig
    | CustomOfferConfig
    | InterestOfferConfig
    | MoveLoanOfferConfig
    | DisclaimerOfferConfig
    | InterestOfferConfig
    | NewLoanOfferConfig;

export default Offers;
