import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { setHousingMaintenance, IRootState } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import { MAINTENANCE_STEP, MAX_MAINTENANCE, MIN_MAINTENANCE } from "../../../defaults";
import DisclaimerComponent from "../../typography/disclaimer_component";

export default function HousingMaintenanceStepComponent(props) {
    const { maintenance, housingType } = useSelector((state: IRootState) => {
        return {
            maintenance: state.userEditingData?.household?.maintenance || 0,
            housingType: state.userEditingData?.household?.housingType,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeSize(value: number) {
        props.onValidate(value >= MIN_MAINTENANCE);
        dispatch(setHousingMaintenance(value));
    }

    React.useEffect(() => {
        props.onValidate(maintenance >= MIN_MAINTENANCE);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="housing_maintenance"
                unit={t("common:sek")}
                onChange={onChangeSize}
                min={MIN_MAINTENANCE}
                step={MAINTENANCE_STEP}
                defaultMax={MAX_MAINTENANCE}
                label={t("housing:input-maintenance")}
                value={maintenance}
            ></NumberComponent>
            <DisclaimerComponent>{t(`housing:input-${housingType}-maintenance-disclaimer`)}</DisclaimerComponent>
        </IndataStepComponent>
    );
}
