import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setAdultAge, setAdultIncome } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import {
    ADULT_INCOME_STEP,
    ADULT_AGE_MIN,
    ADULT_AGE_SLIDER_STEP,
    ADULT_AGE_MAX_SLIDER,
    ADULT_INCOME_MIN,
    ADULT_INCOME_MAX_SLIDER,
} from "../../../defaults";
import { getAdult } from "../../../selectors/household";
import OverlineComponent from "../../typography/overline_component";

export default function HousingAdultStepComponent(props) {
    const { age, income } = useSelector((state: IRootState) => getAdult(state.userEditingData, props.index));

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeAge(value: number) {
        props.onValidate(value >= ADULT_AGE_MIN);
        dispatch(setAdultAge({ index: props.index, value }));
    }

    function onChangeIncome(value: number) {
        props.onValidate(value > ADULT_INCOME_MIN);
        dispatch(setAdultIncome({ index: props.index, value }));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <OverlineComponent>{t("housing:adult-enumeration", { index: props.index + 1 })}</OverlineComponent>
            <NumberComponent
                dataCy="adult_age_input"
                name={"housing_adult_" + props.index + "_age"}
                unit={t("common:years")}
                onChange={onChangeAge}
                min={ADULT_AGE_MIN}
                step={ADULT_AGE_SLIDER_STEP}
                defaultMax={ADULT_AGE_MAX_SLIDER}
                label={t("housing:input-adult-age")}
                value={age}
            ></NumberComponent>
            <NumberComponent
                dataCy="adult_income_input"
                name={"housing_adult_" + props.index + "_income"}
                unit={t("common:sek")}
                onChange={onChangeIncome}
                min={ADULT_INCOME_MIN}
                step={ADULT_INCOME_STEP}
                defaultMax={ADULT_INCOME_MAX_SLIDER}
                label={t("housing:input-adult-income")}
                value={income}
            ></NumberComponent>
        </IndataStepComponent>
    );
}
