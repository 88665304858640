import React from "react";
import { IRootState } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import { constructUserDataInterface, IUserDataInterface } from "../../user_data_interface";
import { useTracking } from "react-tracking";
import { trackLead } from "../../utility/analytics";
import { getMonthlyInterestExpenseAfterDeduction, getTotalYearlyInterestExpenseForExistingMortgage } from "../../functions/household";
import { dynamicRounding } from "../../utility/number_formatter";
import { applyBankKalpScenariosToUserData } from "../../functions/calculations";
import DisclaimerComponent from "../typography/disclaimer_component";
import { useTranslation } from "react-i18next";
import { AccessibleButton } from "../../components/accessibility/accessible_components";

export interface MoveLoanOfferConfig {
    name: string;
    description: string;
    alternativeDescription: string;
    interest: number;
    threshold?: number;
    buttonText: string;
    disclaimer?: string;
    onClick: (userData: IUserDataInterface) => void;
}

export interface MoveLoanOfferProps {
    config: MoveLoanOfferConfig;
}

function calculateInterestDifference(result, userData, userDataInterface, interestRate) {
    if (result?.housing == null || userDataInterface?.mortgageAmount == null) {
        return 0;
    }

    const currentInterest = getTotalYearlyInterestExpenseForExistingMortgage(userData);

    const amortizationScenario = result.housing.amortization?.scenarioNow;
    const amortizationNow = result.housing.amortization?.now;
    let amortization = 0;
    if (Number.isFinite(amortizationNow)) {
        amortization = amortizationNow;
    }
    if (Number.isFinite(amortizationScenario)) {
        amortization = amortizationScenario;
    }
    const newInterest = getMonthlyInterestExpenseAfterDeduction(userDataInterface.mortgageAmount, amortization, interestRate) * 12;
    return Math.round(currentInterest - newInterest);
}

export default function MoveLoanOfferComponent(props: MoveLoanOfferProps) {
    const { config } = props;
    const tracking = useTracking();
    const { t } = useTranslation();

    const { userData, scenarioData, result } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            scenarioData: state.scenarioData,
            result: state.result,
        };
    });

    const appliedUserData = applyBankKalpScenariosToUserData(userData, scenarioData, undefined);

    const userDataInterface = constructUserDataInterface(appliedUserData);
    const yearlySaving = calculateInterestDifference(result, appliedUserData, userDataInterface, props.config.interest);

    const showAmount = yearlySaving > (props.config.threshold || 0);

    function onClick() {
        if (config.onClick) {
            tracking.trackEvent(trackLead("click-offer", `offer-${config.name}`));
            config.onClick(userDataInterface);
        }
    }

    const amount = t("common:saving-per-year", {
        saving: dynamicRounding(yearlySaving),
    });

    return (
        <React.Fragment>
            <h4>{showAmount ? config.description : config.alternativeDescription}</h4>
            {showAmount && <h2>{amount}</h2>}
            <AccessibleButton className="lead" onClick={onClick}>
                {config.buttonText}
            </AccessibleButton>
            {config.disclaimer && <DisclaimerComponent>{config.disclaimer}</DisclaimerComponent>}
        </React.Fragment>
    );
}
