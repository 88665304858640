import * as React from "react";
import { useInView } from "react-intersection-observer";

// Mainly for IE11. Since we only use this for "nice to have animations" (at the
// time of writing) it feels fine to return inView = false and not start the
// animation. The alternative is to inlude a rather large polyfill.
const useInViewPolyfill = () => {
    const ref = React.useCallback(() => {
        return;
    }, []);
    const inView = false;

    return {
        ref,
        inView,
    };
};

const useObserveHook = typeof window.IntersectionObserver === "undefined" ? useInViewPolyfill : useInView;

export default useObserveHook;
