import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import NumberComponent from "../form_controls/number_component";
import OverlineComponent from "../typography/overline_component";
import { ScenarioTemplate, IncompleteStateScenario, IScenarioProps } from "../scenarios";
import SelectComponent from "../form_controls/select_component";
import { IRootState, resetWorkPartTimeScenario, setWorkPartTimeScenario } from "../../reducers/rootReducer";
import { ScenarioEvent } from "../../utility/analytics";
import { RETIREMENT_AGE } from "../../defaults";
import { formatLocalAmount } from "../../utility/number_formatter";

export const WorkPartTimeScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();

    const userData = useSelector((state: IRootState) => state.userData);

    if (!userData) {
        return <IncompleteStateScenario name={t("scenario:work-part-time")} onBack={onBack} category={t("dynamic:family")} />;
    } else {
        return <WorkPartTimeScenarioWithAvailableUserData onBack={onBack} onEngagement={onEngagement} />;
    }
};

const WorkPartTimeScenarioWithAvailableUserData = (props) => {
    const { onBack, onEngagement } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userDataAdults, scenarioAdults, forecastPeriod } = useSelector((state: IRootState) => {
        return {
            userDataAdults: state.userData?.household?.adults,
            scenarioAdults: state.scenarioData?.adultsWorkPartTime,
            forecastPeriod: state.forecastPeriod,
        };
    });
    const adults = scenarioAdults || userDataAdults;
    const scenarioActive = !!scenarioAdults;

    const fromYearList = (selectIndex: number) => {
        const fromYearItems = [];
        for (let year = 0; year <= forecastPeriod; year++) {
            const value = year.toString();
            const label = year === 0 ? t("prognosis:today") : t("scenario:renovation-year-option", { years: year });
            fromYearItems.push({
                id: selectIndex + value,
                value,
                label,
            });
        }
        return fromYearItems;
    };

    const periodList = (selectIndex: number) => {
        const adult = adults[selectIndex];
        let years = adult.partTimeFrom !== undefined ? forecastPeriod + 1 - adults[selectIndex]?.partTimeFrom : forecastPeriod + 1;
        if (adult.age + years >= RETIREMENT_AGE) {
            years = RETIREMENT_AGE - adult.age;
        }
        const periodItems = [];
        for (let year = 1; year <= years; year++) {
            const value = year.toString();
            const label = t("scenario:for-years", { year });
            periodItems.push({
                id: selectIndex + value,
                value,
                label,
            });
        }
        return periodItems;
    };

    const setEmploymentRate = (index, value) => {
        onEngagement(ScenarioEvent.WORK_PART_TIME);
        const adult = adults[index];
        const rate = Number.parseInt(value) / 100;
        const employmentRate = rate < 1 ? rate : 1;
        const partTimeFrom = adult.partTimeFrom !== undefined ? adult.partTimeFrom : 0;
        const partTimePeriod = adult.partTimePeriod || forecastPeriod + 1;

        const adultsDefined = adults.map((adult) => {
            return { ...adult };
        });
        Object.assign(adultsDefined[index], {
            employmentRate,
            partTimeFrom,
            partTimePeriod,
        });

        dispatch(setWorkPartTimeScenario({ value: adultsDefined }));
    };

    const setFromYear = (index, item) => {
        onEngagement(ScenarioEvent.WORK_PART_TIME);
        const value = Number.parseInt(item.value);
        const adult = adults[index];
        const employmentRate = adult.employmentRate !== undefined ? adult.employmentRate : 1;
        const partTimeFrom = value;
        const partTimePeriod =
            adult.partTimePeriod && forecastPeriod + 1 - value >= adult.partTimePeriod ? adult.partTimePeriod : forecastPeriod + 1 - value;

        const adultsDefined = adults.map((adult) => {
            return { ...adult };
        });
        Object.assign(adultsDefined[index], {
            employmentRate,
            partTimeFrom,
            partTimePeriod,
        });

        dispatch(setWorkPartTimeScenario({ value: adultsDefined }));
    };

    const setPeriod = (index, item) => {
        onEngagement(ScenarioEvent.WORK_PART_TIME);
        const partTimePeriod = Number.parseInt(item.value);
        const adult = adults[index];
        const employmentRate = adult.employmentRate !== undefined ? adult.employmentRate : 1;
        const partTimeFrom = adult.partTimeFrom ? adult.partTimeFrom : 0;

        const adultsDefined = adults.map((adult) => {
            return { ...adult };
        });
        Object.assign(adultsDefined[index], {
            employmentRate,
            partTimeFrom,
            partTimePeriod,
        });

        dispatch(setWorkPartTimeScenario({ value: adultsDefined }));
    };

    const resetScenario = () => dispatch(resetWorkPartTimeScenario());

    const workPartTimeElements = adults.map((adult, index) => {
        const overline = (
            <OverlineComponent>
                {`${t("housing:adult-enumeration", {
                    index: (index + 1).toString(),
                })} (${t("scenario:salary", {
                    income: formatLocalAmount(adult.income),
                })}, ${adult.age} ${t("common:years")})`}
            </OverlineComponent>
        );

        if (adult.age >= RETIREMENT_AGE) {
            return (
                <div key={"work-part-time-" + index}>
                    {overline}
                    <h4>
                        {t("scenario:work-part-time-retired", {
                            age: RETIREMENT_AGE,
                        })}
                    </h4>
                </div>
            );
        }

        const selectedEmploymentRate = adult.employmentRate !== undefined ? Math.round(adult.employmentRate * 100) : 100;
        const selectedFrom = adult.partTimeFrom !== undefined ? adult.partTimeFrom : 0;
        const selectedPeriod = adults[index].partTimePeriod || forecastPeriod + 1;

        return (
            <div key={"work-part-time-" + index} className="input-enumeration">
                {overline}
                <NumberComponent
                    name={"work_part_time_scenario-" + index}
                    label={t("scenario:work-time")}
                    unit={"%"}
                    onChange={setEmploymentRate.bind(this, index)}
                    min={0}
                    step={5}
                    defaultMax={100}
                    value={selectedEmploymentRate}
                ></NumberComponent>
                <div className="input-group">
                    <SelectComponent
                        id={"work-part-time-from-" + index}
                        onChange={setFromYear.bind(this, index)}
                        items={fromYearList(index)}
                        value={index + selectedFrom.toString()}
                        label={t("scenario:start")}
                    ></SelectComponent>
                    <SelectComponent
                        id={"work-part-time-period-" + index}
                        onChange={setPeriod.bind(this, index)}
                        items={periodList(index)}
                        value={index + selectedPeriod.toString()}
                        label={t("scenario:nr-of-years")}
                    ></SelectComponent>
                </div>
            </div>
        );
    });

    return (
        <ScenarioTemplate
            name={t("scenario:work-part-time")}
            onBack={onBack}
            onReset={resetScenario}
            scenarioActivated={scenarioActive}
            category={t("dynamic:family")}
        >
            {workPartTimeElements}
        </ScenarioTemplate>
    );
};
