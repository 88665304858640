import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
import DisclaimerComponent from "../typography/disclaimer_component";
import UserData from "../../models/user_data";
import { IRootState } from "../../reducers/rootReducer";
import { trackLead } from "../../utility/analytics";
import { applyBankKalpScenariosToUserData } from "../../functions/calculations";
import { isDefined } from "../../utility/types";
import { AccessibleButton } from "../accessibility/accessible_components";
import { minAmortizationRate } from "../../functions/installment";
import { forecastKalpWithInterest } from "../../functions/kalp";
import { getMaxLoanByIncomeRequirement } from "../../functions/household";
import { getMaxLoanByDebtRatio as getMaxLoanByDebtRatio } from "../../functions/loans";
import { getCurrentHouseholdIncome } from "../../selectors/household";
import { getDownpaymentRateAtInterestDeductionThreshold } from "../../functions/interest";
import { constructUserDataInterface, IUserDataInterface } from "../../user_data_interface";
import { dynamicRounding, formatInterest, formatLocalAmount } from "../../utility/number_formatter";
import {
    BASE_DEDUCTION_FACTOR,
    DEDUCTION_THRESHOLD,
    EXTRA_DEDUCTION_FACTOR,
    HIGH_DOWNPAYMENT_RATE,
    LOW_DOWNPAYMENT_RATE,
    MONTHS_IN_YEAR,
} from "../../defaults";

export interface NewLoanOfferConfig {
    name: string;
    description: string;
    buttonText: string;
    disclaimer?: string;
    maxDebtRatio?: number;
    onClick: (userData: IUserDataInterface) => void;
}

export interface NewLoanOfferProps {
    config: NewLoanOfferConfig;
}

interface IntervalProps {
    downpaymentRateThresholds: Array<number>;
    downpayment: number;
    userData: UserData;
    calculationInterestRate: number;
}
interface IntervalData {
    lowestDownpaymentRate: number;
    highestDownpaymentRate: number;
    loanExpensesDelta: number;
}

function calculateLoanByDownpayment(downpayment: number, downpaymentRate: number): number {
    return downpayment / downpaymentRate - downpayment;
}

function getDownpaymentRateThresholds(
    maxLoanByIncome: number,
    downpayment: number,
    minDownpaymentRate: number,
    calculationInterestRate: number,
): Array<number> {
    const downpaymentRateThresholds = [minDownpaymentRate, LOW_DOWNPAYMENT_RATE, HIGH_DOWNPAYMENT_RATE, 1];

    const maxHousingValueByIncome = downpayment + maxLoanByIncome;
    const thresholdByIncome = downpayment / maxHousingValueByIncome;
    if (thresholdByIncome > minDownpaymentRate) {
        downpaymentRateThresholds.push(thresholdByIncome);
    }
    const downpaymentRateAtInterestDeductionThreshold = getDownpaymentRateAtInterestDeductionThreshold(downpayment, calculationInterestRate);
    if (downpaymentRateAtInterestDeductionThreshold > minDownpaymentRate) {
        downpaymentRateThresholds.push(downpaymentRateAtInterestDeductionThreshold);
    }

    return downpaymentRateThresholds;
}

function getDownpaymentRateInterval(props: IntervalProps): IntervalData {
    const { downpaymentRateThresholds, downpayment, userData, calculationInterestRate } = props;

    downpaymentRateThresholds.sort();
    const intervalData = {
        lowestDownpaymentRate: downpaymentRateThresholds[0],
        highestDownpaymentRate: 0,
        loanExpensesDelta: 0,
    };

    for (let i = 0; i < downpaymentRateThresholds.length; i++) {
        const threshold = downpaymentRateThresholds[i];
        const housingValue = Math.floor(downpayment / threshold);
        userData.household.price = housingValue;
        const currentKALP = forecastKalpWithInterest(userData, calculationInterestRate);

        if (currentKALP <= 0) {
            intervalData.lowestDownpaymentRate = threshold;
            intervalData.loanExpensesDelta = currentKALP;
            intervalData.highestDownpaymentRate = threshold;
        } else {
            intervalData.highestDownpaymentRate = threshold;
            break;
        }
    }
    return intervalData;
}

function calculateMaxLoanAmount(
    intervalData: IntervalData,
    downpayment: number,
    householdIncome: number,
    calculationInterestRate: number,
    additionalMortgageAmount: number,
): number {
    const { lowestDownpaymentRate, highestDownpaymentRate, loanExpensesDelta: kalp } = intervalData;
    const highestHousingValue = Math.floor(downpayment / lowestDownpaymentRate);
    const highestLoanAmount = highestHousingValue - downpayment;
    const amortizationRate = minAmortizationRate({
        price: highestHousingValue,
        loan: highestLoanAmount,
        income: householdIncome,
        additionalMortgageAmount,
    });
    const amortization = highestLoanAmount * amortizationRate;
    const interestExpenses = highestLoanAmount * calculationInterestRate;
    const maxInterestExpenseAfterBaseDeduction = DEDUCTION_THRESHOLD * BASE_DEDUCTION_FACTOR;
    let interestExpensesAfterDeduction;
    const useExtraDeductionRate = interestExpenses > DEDUCTION_THRESHOLD;
    if (useExtraDeductionRate) {
        interestExpensesAfterDeduction = (interestExpenses - DEDUCTION_THRESHOLD) * EXTRA_DEDUCTION_FACTOR + maxInterestExpenseAfterBaseDeduction;
    } else {
        interestExpensesAfterDeduction = interestExpenses * BASE_DEDUCTION_FACTOR;
    }
    let loanExpensesForMaxLoan = amortization + interestExpensesAfterDeduction - Math.abs(kalp) * MONTHS_IN_YEAR;
    let loanExpensesFactor;
    if (useExtraDeductionRate) {
        const baseDeductionConversion = DEDUCTION_THRESHOLD * (EXTRA_DEDUCTION_FACTOR - BASE_DEDUCTION_FACTOR);
        loanExpensesForMaxLoan = loanExpensesForMaxLoan + baseDeductionConversion;
        loanExpensesFactor = amortizationRate + EXTRA_DEDUCTION_FACTOR * calculationInterestRate;
    } else {
        loanExpensesFactor = amortizationRate + BASE_DEDUCTION_FACTOR * calculationInterestRate;
    }
    const calculatedLoanAmount = loanExpensesForMaxLoan / loanExpensesFactor;

    const lowestLoanAmount = calculateLoanByDownpayment(downpayment, highestDownpaymentRate);
    const maxLoanAmount = calculatedLoanAmount > lowestLoanAmount ? calculatedLoanAmount : lowestLoanAmount;

    return maxLoanAmount;
}

export default function NewLoanOfferComponent(props: NewLoanOfferProps) {
    const tracking = useTracking();
    const { t } = useTranslation();

    const { config } = props;
    const { userData, scenarioData, minDownpaymentRate, calculationInterestRate } = useSelector((state: IRootState) => {
        return {
            userData: state.userData,
            scenarioData: state.scenarioData,
            minDownpaymentRate: state.minDownpaymentRate,
            calculationInterestRate: state.calculationInterestRate,
        };
    });

    const appliedUserData = applyBankKalpScenariosToUserData(userData, scenarioData, calculationInterestRate);
    const downpayment = appliedUserData?.household?.downpayment;

    if (!isFinite(downpayment)) {
        return null;
    }

    const maxLoanByIncome = getMaxLoanByIncomeRequirement(appliedUserData);
    const downpaymentRateThresholds = getDownpaymentRateThresholds(maxLoanByIncome, downpayment, minDownpaymentRate, calculationInterestRate);

    const intervalData = getDownpaymentRateInterval({
        downpaymentRateThresholds,
        downpayment,
        userData: appliedUserData,
        calculationInterestRate,
    });

    const householdIncome = getCurrentHouseholdIncome(appliedUserData);
    const additionalMortgageAmount = userData.kalp?.additionalHousing?.data?.mortgage?.amount ?? 0;
    const maxLoanAmount = calculateMaxLoanAmount(intervalData, downpayment, householdIncome, calculationInterestRate, additionalMortgageAmount);

    const maxLoanAmountRounded = Math.floor(maxLoanAmount / 1000) * 1000;

    const maxPriceRounded = maxLoanAmountRounded + downpayment;

    appliedUserData.household.price = maxPriceRounded;
    const userDataInterface = constructUserDataInterface(appliedUserData);

    function onClick() {
        if (config.onClick) {
            tracking.trackEvent(trackLead("click-offer", `offer-${config.name}`));
            config.onClick(userDataInterface);
        }
    }

    const maxLoanByDebtRatio = config.maxDebtRatio ? getMaxLoanByDebtRatio(appliedUserData, config.maxDebtRatio) : null;
    const showMaxLoanByDebtRatio = isDefined(maxLoanByDebtRatio) && maxLoanByDebtRatio < maxLoanAmountRounded;
    const existingHousingLoanAmount = appliedUserData?.kalp?.additionalHousing?.data?.mortgage?.amount;
    const maxExtraLoanOfMaxDebt =
        showMaxLoanByDebtRatio && isDefined(existingHousingLoanAmount) ? (maxLoanByDebtRatio as number) - existingHousingLoanAmount : null;

    return (
        <React.Fragment>
            <h4>{config.description}</h4>
            {isDefined(maxLoanAmount) && (
                <h2>
                    {(formatLocalAmount(maxLoanAmountRounded) || "- ") + " kr"}
                    {showMaxLoanByDebtRatio && "*"}
                </h2>
            )}
            <AccessibleButton className="lead" onClick={onClick}>
                {config.buttonText}
            </AccessibleButton>
            <DisclaimerComponent>
                {config.disclaimer ||
                    t("scenario:new-loan-disclaimer", {
                        calculationInterestRate: formatInterest(calculationInterestRate),
                        downpayment: dynamicRounding(downpayment),
                        housingValue: dynamicRounding(maxPriceRounded),
                    })}
                {showMaxLoanByDebtRatio &&
                    t("scenario:new-loan-debt-ratio-disclaimer", {
                        maxDebtRatio: config.maxDebtRatio,
                        maxLoanByDebtRatio: dynamicRounding(maxLoanByDebtRatio as number),
                    })}
                {showMaxLoanByDebtRatio &&
                    isDefined(maxExtraLoanOfMaxDebt) &&
                    t("scenario:new-loan-debt-ratio-extra-loan-disclaimer", {
                        maxExtraLoanOfMaxDebt: dynamicRounding(maxExtraLoanOfMaxDebt),
                    })}
            </DisclaimerComponent>
        </React.Fragment>
    );
}
