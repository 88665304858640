import * as React from "react";
import InputWizardComponent from "../input_wizard";
import { IRootState, saveKalp, setNavigation, ViewMode, Sections } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import KalpChildrenStepComponent from "../steps/kalp/kalp_children_step";
import KalpChildStepComponent from "../steps/kalp/kalp_child_step";
import KalpCarStepComponent from "../steps/kalp/kalp_car_step";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";
import KalpAdditionalMortgageStep from "../steps/kalp/kalp_additional_housing_mortgage_step";
import KalpAdditionalHousingFeesStep from "../steps/kalp/kalp_additional_housing_fees_step";
import KalpAdditionalHousingChoiceStepComponent from "../steps/kalp/kalp_additional_housing_choice_step";
import KalpAdditionalHousingPropertyTaxStep from "../steps/kalp/kalp_additional_housing_property_tax_step";

export default function KalpWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { children, additionalHousing } = useSelector((state: IRootState) => {
        return {
            children: state.userEditingData?.kalp?.children || [],
            additionalHousing: state.userEditingData?.kalp?.additionalHousing,
        };
    });

    function goToResult() {
        dispatch(saveKalp());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.KALP));
        dispatch(setNavigation({ viewMode: ViewMode.Result, section: Sections.Kalp }));
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.KALP));
    }

    const steps = [<KalpChildrenStepComponent key="kalp_children"></KalpChildrenStepComponent>];

    for (let key = 0; key < children.length; ++key) {
        steps.push(<KalpChildStepComponent key={"kalp_child" + key} index={key}></KalpChildStepComponent>);
    }

    steps.push(<KalpCarStepComponent key="kalp_car"></KalpCarStepComponent>);
    steps.push(<KalpAdditionalHousingChoiceStepComponent key="kalp_additional_housing_choice_step"></KalpAdditionalHousingChoiceStepComponent>);

    if (additionalHousing?.data) {
        steps.push(<KalpAdditionalHousingFeesStep key="kalp_additional_housing_fees_step"></KalpAdditionalHousingFeesStep>);
        steps.push(<KalpAdditionalMortgageStep key="kalp_additional_housing_mortgage_step"></KalpAdditionalMortgageStep>);
        steps.push(<KalpAdditionalHousingPropertyTaxStep key="kalp_additional_housing_property_tax_step"></KalpAdditionalHousingPropertyTaxStep>);
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            {steps}
        </InputWizardComponent>
    );
}
