import { MortgageCalculationType } from "./mortgage";
import { InputValueState } from "./input_state";
import { IAmortizationScenario } from "reducers/rootReducer";

export class Person {
    age: number;
    income: number;
    employmentRate?: number;
    partTimeFrom?: number;
    partTimePeriod?: number;
}

export enum ChildHouseholdRate {
    HALF_TIME = "HALF_TIME",
    FULL_TIME = "FULL_TIME",
}
export class Child {
    age: number;
    householdRate: ChildHouseholdRate;
}
export class Loan {
    amount: number;
    amortization: number;
    interest: number;
    amortizationRatio?: number;
}

export enum AmortizationType {
    minimal,
    flat,
    constant,
}
export enum HousingType {
    condominium = "condominium",
    house = "house",
    cottage = "cottage",
}

export class HouseholdData {
    calculationType?: MortgageCalculationType;
    amortizationRequirementsAsDefault?: boolean;
    housingType?: HousingType;
    price?: number;
    estimatedValue?: number;
    downpayment?: number;
    mortgages?: Array<Loan>;
    fee?: number;
    propertyTax?: number;
    amortizationType?: AmortizationType;
    maintenance?: number;
    adults?: Array<Person>;
}
export class LoanData {
    studentLoans?: Array<Loan>;
    privateLoans?: Array<Loan>;
    carLoans?: Array<Loan>;
}
export class SavingsData {
    accountAmount: number;
    accountMonthlySavings: number;
    investmentsAmount: number;
    investmentsMonthlySavings: number;
}

export class AdditionalHousingData {
    mortgage?: Loan;
    propertyTax?: number;
    fees?: number;
    maintenance?: number;
}

export class KalpData {
    children?: Array<Child>;
    carCost?: number;
    additionalHousing: InputValueState<AdditionalHousingData>;
}

export default class UserData {
    household?: HouseholdData;
    kalp?: KalpData;
    loans?: LoanData;
    savings?: SavingsData;
    scenarioDefinedInterest?: number;
    scenarioDefinedAmortization?: IAmortizationScenario;
    scenarioDefinedMaintenance?: number;
    scenarioDefinedChildren?: Array<Child>;
    scenarioDefinedAdults?: Array<Person>;
    scenarioDefinedInvestmentInterest?: number;
    scenarioDefinedRenovationTarget?: string;
    scenarioDefinedRenovationCost?: number;
    scenarioDefinedRenovationYear?: number;
}
