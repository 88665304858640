import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { IRootState, setCarLoans } from "../../../reducers/rootReducer";
import NumberStepComponent from "../../form_controls/number_step_component";
import { useTranslation } from "react-i18next";

export default function LoanSecondStepComponent(props) {
    const { carLoans } = useSelector((state: IRootState) => {
        return {
            carLoans: state.userEditingData?.loans?.carLoans?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeCarLoans(value: number) {
        props.onValidate(true);
        dispatch(setCarLoans(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberStepComponent
                name="carloans"
                icon="car"
                onChange={onChangeCarLoans}
                min={0}
                max={10}
                unit={t("common:pcs")}
                subLabel={t("loan:input-carloans")}
                value={carLoans}
            ></NumberStepComponent>
        </IndataStepComponent>
    );
}
