import UserData from "./models/user_data";
import { getHouseholdIncome, getTotalMortgageAmount, getHousingPriceByCalculationType } from "./selectors/household";
import Result from "./models/result";

export interface IUserDataInterface {
    calculationInterestKalp?: number;
    calculationType?: string;
    housingPrice: number | void;
    householdIncome: number | void;
    mortgageAmount: number | void;
    housingType: string;
    propertyTax: number | void;
    fee: number | void;
    maintenance: number | void;
    downpayment: number | void;
}

export const constructUserDataInterface = (userData?: UserData, result?: Result): IUserDataInterface => {
    return {
        calculationInterestKalp: result && result.calculationInterestKalp,
        calculationType: userData && userData.household?.calculationType?.toString(),
        householdIncome: userData && getHouseholdIncome(userData),
        housingPrice: userData && getHousingPriceByCalculationType(userData),
        mortgageAmount: userData && getTotalMortgageAmount(userData),
        housingType: userData?.household?.housingType?.toString() || "",
        propertyTax: userData?.household?.propertyTax,
        fee: userData?.household?.fee,
        maintenance: userData?.household?.maintenance,
        downpayment: userData?.household?.downpayment,
    };
};
