import * as React from "react";
import * as d3 from "d3";

interface IGridProps {
    scale: d3.ScaleLinear<number, number>;
    ticks?: number;
}

abstract class Grid<P extends IGridProps> extends React.Component<P> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    abstract createAxis(): d3.Axis<d3.AxisDomain>;

    getGrid(): d3.Axis<d3.AxisDomain> {
        const axis = this.createAxis();

        return axis;
    }

    componentDidMount() {
        if (this.ref.current) {
            d3.select(this.ref.current).call(this.getGrid());
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            d3.select(this.ref.current).transition().call(this.getGrid());
        }
    }

    render() {
        return <g className="grid" ref={this.ref} />;
    }
}

interface IGridXProps extends IGridProps {
    height: number;
}

export class GridX extends Grid<IGridXProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    createAxis(): d3.Axis<d3.AxisDomain> {
        const { scale, ticks, height } = this.props;

        const axis = d3.axisBottom(scale);

        axis.ticks(ticks)
            .tickSize(-height)
            .tickFormat(() => "");

        return axis;
    }

    render() {
        const { height } = this.props;

        return <g className="grid" transform={`translate(0,${height})`} ref={this.ref} />;
    }
}

interface IGridYProps extends IGridProps {
    width: number;
}

export class GridY extends Grid<IGridYProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    createAxis(): d3.Axis<d3.AxisDomain> {
        const { scale, ticks, width } = this.props;

        const axis = d3.axisLeft(scale);

        axis.ticks(ticks)
            .tickSize(-width)
            .tickFormat(() => "");

        return axis;
    }
}
