import * as React from "react";

interface CheckboxProps {
    id: string;
    children: string;
    checked: boolean;
    onChange: (event: unknown) => void;
}

export default function CheckboxComponent(props: CheckboxProps) {
    const { id, checked, onChange } = props;

    return (
        <div>
            <input type="checkbox" id={id} checked={checked} onChange={onChange} />
            <label className={`input-label ${checked ? "icon-checkbox_checked" : "icon-checkbox_unchecked"}`} htmlFor={id}>
                {props.children}
            </label>
        </div>
    );
}
