import * as React from "react";
import * as d3 from "d3";

interface IAxisProps {
    scale: d3.ScaleLinear<number, number>;
    ticks?: number;
    tickFormat?: (x: number) => string;
}

abstract class Axis<P extends IAxisProps> extends React.Component<P> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    abstract createAxis(): d3.Axis<d3.AxisDomain>;

    getAxis(): d3.Axis<d3.AxisDomain> {
        const { ticks, tickFormat } = this.props;

        const axis = this.createAxis();

        if (ticks) axis.ticks(ticks);

        if (tickFormat) axis.tickFormat(tickFormat);

        return axis;
    }

    componentDidMount() {
        if (this.ref.current) {
            d3.select(this.ref.current).call(this.getAxis());
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            d3.select(this.ref.current).transition().call(this.getAxis());
        }
    }

    render() {
        return <g className="axis axis-y" ref={this.ref} />;
    }
}

type IAxisLeftProps = IAxisProps;

export class AxisLeft extends Axis<IAxisLeftProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    createAxis(): d3.Axis<d3.AxisDomain> {
        return d3.axisLeft(this.props.scale);
    }
}

interface IAxisBottomProps extends IAxisProps {
    height: number;
}

export class AxisBottom extends Axis<IAxisBottomProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    createAxis(): d3.Axis<d3.AxisDomain> {
        return d3.axisBottom(this.props.scale);
    }

    render() {
        const { height } = this.props;

        return <g className="axis axis-x" transform={`translate(0,${height})`} ref={this.ref} />;
    }
}
