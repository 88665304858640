import * as React from "react";

export class IndataStepProps {
    active?: boolean;
    onValidate?: (valid: boolean) => void;
    children: React.ReactNode;
}

export default class IndataStepComponent extends React.Component<IndataStepProps> {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="indata-step">{this.props.children}</div>;
    }
}
