import { TFunction } from "i18next";
import { formatLocalAmount } from "./number_formatter";

export function formatYTickLabel(amount: number) {
    return `${formatLocalAmount(amount, 0, 1)} kr`;
}

export function formatYear(year: number, t: TFunction) {
    return year !== 0 ? t("prognosis:year-enumeration", { year }) : t("prognosis:today");
}
