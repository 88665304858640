import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { ScenarioTemplate, IncompleteStateScenario, IScenarioProps } from "../scenarios";
import { IRootState, resetMortgageScenario, setMortgageScenario } from "../../reducers/rootReducer";
import NumberComponent from "../form_controls/number_component";
import { ESTIMATED_HOUSING_VALUE, HOUSING_MIN_VALUE, HOUSING_PRICE_STEP, HOUSING_MAX_PRICE } from "../../defaults";
import { getMortgage } from "../../functions/household";
import { MortgageCalculationType } from "../../models/mortgage";
import { ScenarioEvent } from "../../utility/analytics";

export const MortgageScenario = (props: IScenarioProps) => {
    const { onBack, onEngagement } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { scenarioData, userData, minDownpaymentRate } = useSelector((state: IRootState) => {
        return {
            scenarioData: state.scenarioData?.mortgageData,
            userData: state.userData,
            minDownpaymentRate: state.minDownpaymentRate,
        };
    });
    const resetScenario = () => dispatch(resetMortgageScenario());
    const calculatedMortgage = getMortgage(userData, scenarioData);

    if (!Number.isFinite(calculatedMortgage)) {
        return (
            <IncompleteStateScenario
                description={t("scenario:scenario-mortgage-no-loans")}
                name={t("scenario:mortgage-header")}
                onBack={onBack}
                category={t("dynamic:house")}
            />
        );
    }

    const scenarioActivate = !!scenarioData;

    const roundToStep = (value: number) => {
        return Math.floor(value / HOUSING_PRICE_STEP) * HOUSING_PRICE_STEP;
    };

    const getPrice = () => {
        if (userData?.household && userData?.household?.calculationType === MortgageCalculationType.new) {
            return userData.household.price;
        }

        return userData?.household?.estimatedValue;
    };

    const value = scenarioData?.housingValue || getPrice() || ESTIMATED_HOUSING_VALUE;
    const maxMortgage = roundToStep(value * (1 - minDownpaymentRate));

    const mortgage = Number.isFinite(scenarioData?.mortgage)
        ? scenarioData.mortgage
        : Number.isFinite(calculatedMortgage)
          ? calculatedMortgage
          : maxMortgage;

    const onChangeValue = (val) => {
        onEngagement(ScenarioEvent.MORTGAGE);
        dispatch(setMortgageScenario({ value: val, mortgage }));
    };

    const onChangeMortgage = (val) => {
        onEngagement(ScenarioEvent.MORTGAGE);
        dispatch(setMortgageScenario({ value, mortgage: val }));
    };

    const loanRatio = Math.round((mortgage / value) * 100) + " %";

    return (
        <React.Fragment>
            <ScenarioTemplate
                name={t("scenario:mortgage-header")}
                onBack={onBack}
                onReset={resetScenario}
                scenarioActivated={scenarioActivate}
                category={t("dynamic:house")}
            >
                <NumberComponent
                    name="mortgage_scenario_value"
                    unit={"kr"}
                    onChange={onChangeValue}
                    min={HOUSING_MIN_VALUE}
                    step={HOUSING_PRICE_STEP}
                    defaultMax={HOUSING_MAX_PRICE}
                    label={t("scenario:mortgage-value")}
                    value={value}
                ></NumberComponent>
                <NumberComponent
                    name="mortgage_scenario_mortgage"
                    unit={"kr"}
                    onChange={onChangeMortgage}
                    min={0}
                    step={HOUSING_PRICE_STEP}
                    defaultMax={maxMortgage}
                    label={t("scenario:mortgage-size")}
                    detailLabel={loanRatio}
                    value={mortgage}
                ></NumberComponent>
            </ScenarioTemplate>
        </React.Fragment>
    );
};
