import * as React from "react";
import { formatLocalAmount, fromDecimal } from "../../utility/number_formatter";
import { useState, useEffect } from "react";

const MAX_INPUT_FACTOR = 100;

interface NumberProps {
    label: string;
    detailLabel?: string;
    name: string;
    value: number;
    min: number;
    defaultMax: number;
    step: number;
    unit?: string;
    dataCy?: string;
    onChange: (value: number) => void;
}

export default function NumberComponent(props: NumberProps) {
    const { name, label, min, defaultMax, step, unit, onChange } = props;
    let sliderRef: HTMLInputElement;

    useEffect(() => {
        onSliderChangeShadow();
    });

    useEffect(() => {
        setSliderMax(defaultMax);
    }, [defaultMax]);

    useEffect(() => {
        setTextValue(props.value.toString());
    }, [props.value]);

    const format = (value: number) => {
        if (unit === "%") {
            const val = formatLocalAmount(value, 2, 2);
            return val.replace(",", ".");
        }
        return formatLocalAmount(value, 0, 1);
    };

    const capValue = (value: number) => {
        return Math.max(value || 0, 0);
    };

    const value = capValue(props.value);
    const [textValue, setTextValue] = useState(format(value));
    const [editing, setEditing] = useState(false);
    const [sliderMax, setSliderMax] = useState(defaultMax);

    if (value !== props.value) {
        onChange(value);
    }

    if (textValue !== format(value) && !editing) {
        setTextValue(format(value));
    }

    const margin = () => {
        return 0.2 + textValue.replace(/\s/g, "").length * 0.4 + "em";
    };

    const maxInput = defaultMax * MAX_INPUT_FACTOR;

    const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value ? event.target.value.replace(/\s/g, "") : "";

        if (parseFloat(value) === 0) {
            if (value.length >= 10) return;
            const formattedZeroes = value.replace(/\d{3}(?=.)/g, "$& ");
            setEditing(true);
            setTextValue(formattedZeroes);
            return;
        }

        const parsedValue = fromDecimal(value);
        setEditing(true);

        if (Number.isFinite(parsedValue) && parsedValue > maxInput) {
            return;
        }

        if (unit === "%" || Number.isNaN(parsedValue)) {
            // eslint-disable-next-line no-useless-escape
            value = value.replace(/[^\d\.,]/, "");
            setTextValue(value);
        } else {
            setTextValue(format(parsedValue));
        }
    };

    const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setTextValue(format(value));
        onChange(value);
    };

    const onSliderChangeShadow = () => {
        const thumb_position = Math.round((98 * (Number(sliderRef.value) - min)) / (sliderMax - min) + 1);

        sliderRef.style.setProperty("background-size", `${thumb_position}% 100%`, "important");
    };

    const blurOnEnter = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.target.blur();
        }
    };

    const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value ? event.target.value.replace(/\s/g, "") : "0";

        // eslint-disable-next-line no-useless-escape
        value = value.replace(/[^\d\.,]/, "");
        const parsedValue = fromDecimal(value);
        const numericValue = capValue(parsedValue);
        const roundedUpSliderValue = Math.ceil(fromDecimal(value) / step) * step;

        setSliderMax(Math.max(roundedUpSliderValue, defaultMax));
        setTextValue(format(numericValue));
        onChange(numericValue);
        setEditing(false);
    };

    const isDecimalInputType = props.unit === "%";
    const renderInput = (
        <input
            data-cy={props.dataCy}
            className="number-input"
            type={isDecimalInputType ? "number" : "tel"}
            onChange={onTextChange}
            name={name}
            min={min}
            max={sliderMax}
            value={textValue}
            onFocus={(event) => setTimeout(() => event.target.select(), 50)}
            onBlur={onBlur}
            onKeyUp={blurOnEnter}
            step={step}
        />
    );

    return (
        <div>
            {label ? <label>{`${label} ${props.detailLabel ? props.detailLabel : ""}`}</label> : null}

            <div className="number-input-wrapper">
                {renderInput}
                <span
                    className="input-unit"
                    ref={(node) => {
                        if (node) node.style.setProperty("margin-left", margin(), "important");
                    }}
                >
                    {unit}
                </span>
            </div>
            <input
                data-cy={props.dataCy}
                type="range"
                onInput={onSliderChange}
                onChange={() => {
                    return void 0;
                }}
                name={name}
                min={min}
                max={sliderMax}
                step={step}
                value={value}
                ref={(el) => (sliderRef = el)}
            />
        </div>
    );
}
