import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Chart, IInfoTooltipProps } from "./chart";
import { Area } from "./area";
import { Line } from "./line";
import { LinearPoint, linear } from "../../models/linear";
import { formatYear, formatYTickLabel } from "../../utility/chart";
import { DataPoint, IResult } from "../../models/result";
import { TooltipEvent } from "../../utility/analytics";

export interface IInsightChartProps extends WithTranslation {
    id: string;
    className?: string;
    summary: DataPoint;
    data: IResult;
    period: number;
    infoTooltips?: Array<IInfoTooltipProps>;
    onEngagement: (label: TooltipEvent) => void;
}

class InsightsChart extends React.Component<IInsightChartProps> {
    constructor(props) {
        super(props);
    }

    getFilteredData(): IResult {
        const { data } = this.props;
        // don't keep keys with undefined / null values
        const filteredEntries = Object.entries(data)
            .filter(([, value]) => !!value)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        return filteredEntries;
    }

    getLinearPoints(): Array<Array<LinearPoint>> {
        const data = this.getFilteredData();
        let ref: Array<LinearPoint>;

        return Object.keys(data).map((d) => {
            ref = linear(data[d].scenarioFuture || data[d].future, d, ref);
            return ref;
        });
    }

    render() {
        const { id, period, summary, className, t, onEngagement } = this.props;
        const data = this.getFilteredData();

        const areas = this.getLinearPoints();
        const children = areas
            .map((area, i) => {
                return <Area id={areas.length - i} key={`${id}-area-${i}`} label={area[0].label} points={area} onEngagement={onEngagement} />;
            })
            .reverse();

        const chartProps = {
            className: "insights",
            id,
            xTicks: 3,
            yTicks: 5,
            labels: [...Object.keys(data).reverse(), `${id}-summary`],
            summary,
            infoTooltips: this.props.infoTooltips,
        };

        if (className) {
            chartProps.className = `${chartProps.className} ${className}`;
        }

        return (
            <Chart
                {...chartProps}
                xTickFormat={(x) => formatYear(x, t)}
                yTickFormat={(y) => formatYTickLabel(y)}
                onEngagement={onEngagement}
                totalAreaOnTop={false}
            >
                {children}
                <Line key={`${id}-summary`} period={period} points={linear(summary.scenarioFuture || summary.future, "summary")}></Line>
            </Chart>
        );
    }
}

export default withTranslation()(InsightsChart);
