import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { setKalpChildren, IRootState } from "../../../reducers/rootReducer";
import NumberStepComponent from "../../form_controls/number_step_component";
import { useTranslation } from "react-i18next";

export default function KalpChildrenStepComponent(props) {
    const { children } = useSelector((state: IRootState) => {
        return {
            children: state.userEditingData?.kalp?.children?.length || 0,
        };
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangeChildren(value: number) {
        props.onValidate(value > 0);
        dispatch(setKalpChildren(value));
    }

    React.useEffect(() => {
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberStepComponent
                name="kalp_children"
                icon="child"
                onChange={onChangeChildren}
                min={0}
                max={10}
                unit={t("common:pcs")}
                label={t("kalp:input-household-children")}
                subLabel={t("kalp:input-children")}
                value={children}
            ></NumberStepComponent>
        </IndataStepComponent>
    );
}
