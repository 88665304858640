import { MortgageCalculationType } from "../../models/mortgage";
import { OfferModules } from "../../models/offer";
import { IPrognosisParameters, IPrognosisParametersOverrides } from "../../models/config";
import { IUserDataInterface } from "../../user_data_interface";
import PrognosisWidget from "PrognosisWidget";
import { formatLocalAmount } from "../../utility/number_formatter";

function getEffectiveInterestRate(interestRate) {
    return PrognosisWidget.getEffectiveInterest(interestRate);
}

export function getConfiguration(overrides?: IPrognosisParametersOverrides): IPrognosisParameters {
    const minDownpaymentRate = overrides?.minDownpaymentRate ?? 0.15;
    const calculationInterestRate = overrides?.calculationInterestRate ?? 0.07;

    const interestRateOffer =
        window["interestRateOffer"] ||
        function () {
            return 0.02;
        };

    const housingTypes = window["housingTypes"] || undefined;

    const offers = window["offers"] || undefined;

    const params = new URLSearchParams(window.location.search);
    const language = params.has("language") ? params.get("language") : undefined;

    const inEnglish = language === "en";

    const discounts = [
        { rate: 0.6, interest: 0.01, discount: 0.006, contract: "3m" },
        { rate: 0.7, interest: 0.014, discount: 0.002, contract: "1y" },
        { rate: 0.75, interest: 0.015, discount: 0.001, contract: "3y" },
    ];

    return {
        language: language,
        housingTypes,
        minDownpaymentRate: minDownpaymentRate,
        mortgageInterestRateOffer: interestRateOffer,
        calculationInterestRate: calculationInterestRate,
        tipsModules: [
            { id: "high-ltv-move-loan", configuration: { discounts } },
            { id: "low-kalp-move-loan", configuration: { discounts } },
            { id: "low-kalp-new-loan", configuration: { discounts } },
            { id: "interest-discount-new-loan", configuration: { discounts } },
            { id: "interest-discount-move-loan", configuration: { discounts } },
            { id: "high-amortization-new-loan", configuration: { discounts } },
        ],
        tracking: {
            userActivityReportInterval: 10,
            userActivityIdleTimeout: 30,
        },
        interestRateList() {
            return [
                { id: "3months", label: "3 mån - 1 %", value: 0.01 },
                { id: "1year", label: "1 år - 1.5 %", value: 0.015 },
                { id: "3years", label: "3 år - 4 %", value: 0.04 },
            ];
        },
        onEvent: (data) => {
            if (overrides?.onEvent) {
                overrides.onEvent(data);
                return;
            }
        },
        offers: offers || [
            {
                module: OfferModules.newloan,
                show: (_, __, userData: IUserDataInterface) => {
                    const newLoan = userData.calculationType === MortgageCalculationType.new;
                    const userDataAvailable = typeof userData.householdIncome !== "undefined";

                    return {
                        show: newLoan && userDataAvailable,
                        config: {
                            name: "new-loan",
                            description: !inEnglish
                                ? "Baserat på dina uppgifter kan du preliminärt låna:"
                                : "Based on the information provided you can preliminary borrow:",
                            threshold: 0,
                            maxDebtRatio: 5,
                            buttonText: !inEnglish ? "Ansök om bolån" : "Apply for a mortgage",
                            onClick: (userData) => {
                                console.log(userData);
                            },
                        },
                    };
                },
            },
            {
                module: OfferModules.moveloan,
                show(_, __, userData: IUserDataInterface) {
                    const moveLoan = userData.calculationType === MortgageCalculationType.move;
                    const userDataAvailable = typeof userData.householdIncome !== "undefined";

                    const interestRate = 0.011;
                    const discount = 0.003;
                    const period = !inEnglish ? "3 månader" : "3 months";

                    const effectiveInterestRate = getEffectiveInterestRate(interestRate);

                    const formattedInterestRate = formatLocalAmount(Math.round(interestRate * 10000) / 100, 2, 2);
                    const formattedEffectiveInterestRate = formatLocalAmount(Math.round(effectiveInterestRate * 10000) / 100, 2, 2);
                    const formattedInterestRateDiscount = formatLocalAmount(discount * 100, 2, 2);

                    return {
                        show: userDataAvailable && moveLoan,
                        config:
                            userDataAvailable && moveLoan
                                ? {
                                      name: "move-loan",
                                      threshold: 100,
                                      interest: interestRate,
                                      description: !inEnglish
                                          ? "Prognosen indikerar att du har möjlighet att minska dina räntekostnader med:"
                                          : "The forecast indicates that you have the possibility to reduce your mortgage costs with",
                                      alternativeDescription: !inEnglish
                                          ? "Grattis! Du verkar redan idag ha en bra ränta."
                                          : "Congratulations! You seem to have a competitive interest rate already.",
                                      buttonText: !inEnglish ? "Ansök om att flytta ditt bolån" : "Apply for a remortgage",
                                      disclaimer: !inEnglish
                                          ? disclaimer(formattedInterestRateDiscount, formattedInterestRate, period, formattedEffectiveInterestRate)
                                          : disclaimerEN(
                                                formattedInterestRateDiscount,
                                                formattedInterestRate,
                                                period,
                                                formattedEffectiveInterestRate,
                                            ),

                                      onClick() {
                                          console.log(userData);
                                      },
                                  }
                                : null,
                    };
                },
            },
        ],
    };
}

const disclaimer = (discount, interestRate, period, effectiveInterestRate) => `Prognosen baseras på de uppgifter du angivit. 
Summan gäller efter skattereduktion. 
Räntesatserna baseras på att du har rätt till ränterabatt om ${discount} procentenheter. 
Prognosen är inte ett erbjudande. 
Räntan är beräknad med ${interestRate} % bundet på ${period} (effektiv ränta ${effectiveInterestRate} %). 
En individuell prövning av din ansökan måste alltid göras för att ett lån ska bli beviljat.`;

const disclaimerEN = (discount, interestRate, period, effectiveInterestRate) => `The forecast is based on the information that you have provided. 
The amount is calculated with tax deductions. 
The interest rates used are based on your possibility to get a reduction in the interest rate of ${discount} percentage points. 
The forecast is not a binding offer. 
The interest rate is calculated with ${interestRate} % fixed for ${period} (effective interest rate ${effectiveInterestRate} %). 
An individual assessment of your application will be done before the remortgage is approved.`;
