import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IRootState, Sections, setEvent } from "../reducers/rootReducer";
import { OfferModules } from "../models/offer";
import DefaultOfferComponent from "./offers/default_offer";
import React, { useEffect, useState } from "react";
import { constructUserDataInterface } from "../user_data_interface";
import InterestOfferComponent from "./offers/interest_offer";
import MoveLoanOfferComponent from "./offers/moveloan_offer";
import DisclaimerOfferComponent from "./offers/disclaimer_offer";
import CustomOfferComponent from "./offers/custom_offer";
import { AccessibleLi, AccessibleDiv } from "./accessibility/accessible_components";
import NewLoanOfferComponent from "./offers/newloan_offer";
import { useTracking } from "react-tracking";
import { OfferActions, trackOffer } from "../utility/analytics";
import { hasOccured } from "../utility/one_time_events";

export default function OffersComponent() {
    const { t } = useTranslation();
    const [currentSlide, setSlide] = useState(0);
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { userData, selectedSection, scenarioData, offers, result, events } = useSelector((state: IRootState) => {
        return {
            result: state.result,
            userData: state.userData,
            selectedSection: state.selectedSection,
            scenarioData: state.scenarioData,
            offers: state.offers || [],
            events: state.events || [],
        };
    });

    useEffect(() => {
        trackUsage();
    });

    function trackUsage() {
        if (!activeOffers.length) return;

        const currentOffer = activeOffers[currentSlide];
        if (!currentOffer) return;

        const event = trackOffer(OfferActions.PRESENTED, currentOffer.config.name);
        if (!hasOccured(events, event)) {
            tracking.trackEvent(event);
            dispatch(setEvent({ event }));
        }
    }

    function validateOffers() {
        for (const offer of offers) {
            if (Object.keys(OfferModules).indexOf(offer.module) < 0) {
                throw new Error(`Unknown module ${offer.module}`);
            }

            if (!offer.show) {
                throw new Error(`Module ${offer.module} has no callback to determine when to show it and will never be visible.`);
            }
        }
    }
    validateOffers();

    function getActiveOffers() {
        if (!offers) {
            return [];
        }

        return offers
            .map((offer) => {
                const config = offer && offer.show(selectedSection, scenarioData, constructUserDataInterface(userData, result));

                return {
                    module: offer.module,
                    ...config,
                };
            })
            .filter((offer) => offer.show);
    }
    const activeOffers = getActiveOffers();

    if (currentSlide > 0 && currentSlide >= activeOffers.length) {
        setSlide(0);
    }

    function renderOffer(offer, index: number) {
        if (offer.module === OfferModules.default) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <DefaultOfferComponent config={offer.config}></DefaultOfferComponent>
                </div>
            );
        } else if (offer.module === OfferModules.interest) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <InterestOfferComponent config={offer.config}></InterestOfferComponent>
                </div>
            );
        } else if (offer.module === OfferModules.moveloan) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <MoveLoanOfferComponent config={offer.config}></MoveLoanOfferComponent>
                </div>
            );
        } else if (offer.module === OfferModules.disclaimer) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <DisclaimerOfferComponent config={offer.config}></DisclaimerOfferComponent>
                </div>
            );
        } else if (offer.module === OfferModules.custom) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <CustomOfferComponent config={offer.config}></CustomOfferComponent>
                </div>
            );
        } else if (offer.module === OfferModules.newloan) {
            return (
                <div data-cy="offer" key={index} className={index === currentSlide ? "offer-slide active" : "offer-slide"}>
                    <NewLoanOfferComponent config={offer.config}></NewLoanOfferComponent>
                </div>
            );
        }

        throw new Error("Unknown module " + offer.module);
    }

    function offerClass() {
        const classes = ["container offers-container"];

        switch (selectedSection) {
            case Sections.Housing:
                classes.push("housing-insight");
                break;
            case Sections.Kalp:
                classes.push("kalp-insight");
                break;
            case Sections.Loans:
                classes.push("loans-insight");
                break;
            case Sections.Savings:
                classes.push("savings-insight");
                break;
        }

        return classes.join(" ");
    }

    function setActiveOffer(index: number) {
        setSlide(index);
    }

    function nextOffer() {
        setSlide((currentSlide + 1) % activeOffers.length);
    }

    function previousOffer() {
        setSlide((currentSlide - 1 + activeOffers.length) % activeOffers.length);
    }

    function slideAttributes(index: number) {
        if (index === currentSlide) {
            return { className: "active" };
        }
        return {};
    }

    if (activeOffers.length > 0) {
        return (
            <div className={offerClass()}>
                <div className="offers-header">
                    <h3>{t("proposition:header-proposition")}</h3>
                </div>
                <div className="offers-body">
                    {activeOffers.map((offer, index) => renderOffer(offer, index))}
                    {activeOffers.length > 1 ? (
                        <React.Fragment>
                            <div className="slide-steppers">
                                <AccessibleDiv className="slide-stepper-left icon-arrow-left" onClick={previousOffer}></AccessibleDiv>
                                <AccessibleDiv className="slide-stepper-right icon-arrow-right" onClick={nextOffer}></AccessibleDiv>
                            </div>
                            <ul className="slide-indicator">
                                {activeOffers.map((offer, index) => {
                                    return (
                                        <AccessibleLi
                                            {...slideAttributes(index)}
                                            key={index}
                                            onClick={setActiveOffer.bind(null, index)}
                                        ></AccessibleLi>
                                    );
                                })}
                            </ul>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    } else if (offers.length > 0) {
        return (
            <div className={`${offerClass()} disabled`}>
                <div className="offers-header">
                    <h3>{t("proposition:header-proposition")}</h3>
                </div>
                <div className="container-body offers-body disabled">
                    <i className="icon-placeholder icon-offer"></i>
                    <h4>{t("proposition:call-to-action")}</h4>
                </div>
            </div>
        );
    }

    return null;
}
