export class LinearPoint {
    label: string;
    amount: number;
    x: number;
    y: number;
    y0?: number;
}

export function linear(data: Array<number>, label: string, ref?: Array<LinearPoint>): Array<LinearPoint> {
    return data.map((d, i) => ({
        label,
        amount: d,
        x: i,
        y: ref ? ref[i].y + (d || 0) : d || 0,
    }));
}
