import { IMortgageConfiguration, MortgageCalculationType } from "../models/mortgage";

export function getMortgageCalculationTypes(configuration: IMortgageConfiguration): Array<MortgageCalculationType> {
    const types = Object.keys(MortgageCalculationType) as Array<MortgageCalculationType>;

    if (!configuration || !configuration.calculationTypes) {
        return types;
    }

    if (configuration.calculationTypes.length === 0) {
        throw new Error("Cannot pass empty array to mortgage calculation type config");
    }

    if (new Set(configuration.calculationTypes).size !== configuration.calculationTypes.length) {
        throw new Error(`Mortgage calculation type config contains duplicate value`);
    }

    if (configuration.calculationTypes.some((x) => !Object.values(MortgageCalculationType).includes(x))) {
        throw new Error(`Mortgage calculation type config contains invalid value`);
    }

    return configuration.calculationTypes;
}
