import * as React from "react";
import { isEntireElementInViewport } from "../../utility/viewport";

interface InViewAnimatedProps {
    inViewElement: HTMLElement;
    value: string;
    noInitialAnimation?: boolean;
}

export default class InViewAnimated extends React.Component<InViewAnimatedProps> {
    constructor(props) {
        super(props);
    }

    private ref: HTMLElement;
    private animationEnterDelay: ReturnType<typeof setTimeout>;
    private animationExitingDelay: ReturnType<typeof setTimeout>;
    private animationExitDelay: ReturnType<typeof setTimeout>;
    onScroll = this.onScrollEvent.bind(this);
    animation = this.applyAnimation.bind(this);

    componentDidMount() {
        if (this.props.noInitialAnimation) {
            return;
        }
        if (isEntireElementInViewport(this.props.inViewElement)) {
            this.animation();
        } else {
            document.addEventListener("scroll", this.onScroll);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.ref || this.props.value === prevProps.value) {
            return;
        }
        document.removeEventListener("scroll", this.onScroll);
        this.ref.classList.remove("entering");
        this.ref.classList.remove("exiting");
        if (isEntireElementInViewport(this.props.inViewElement)) {
            this.animation();
        } else {
            document.addEventListener("scroll", this.onScroll);
        }
    }

    onScrollEvent() {
        if (!this.ref) {
            document.removeEventListener("scroll", this.onScroll);
            return;
        }
        if (isEntireElementInViewport(this.props.inViewElement)) {
            this.animation();
            document.removeEventListener("scroll", this.onScroll);
        }
    }

    applyAnimation() {
        clearTimeout(this.animationEnterDelay);
        clearTimeout(this.animationExitingDelay);
        clearTimeout(this.animationExitDelay);
        this.animationEnterDelay = setTimeout(() => {
            if (!this.ref) {
                return;
            }
            this.ref.classList.add("entering");
        }, 700);
        this.animationExitingDelay = setTimeout(() => {
            if (!this.ref) {
                return;
            }
            this.ref.classList.remove("entering");
            this.ref.classList.add("exiting");
        }, 840);
        this.animationExitDelay = setTimeout(() => {
            if (!this.ref) {
                return;
            }
            this.ref.classList.remove("exiting");
        }, 1000);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
    }

    render() {
        const { value } = this.props;

        return (
            <span className="animate" ref={(node) => (this.ref = node)}>
                {value}
            </span>
        );
    }
}
