import { IRootState } from "../../reducers/rootReducer";
import { tipsModule } from "../../utility/module_store";
import { isTipsActive, InterestDiscountTipsComponent } from "./interest_discount_new_loan";
import { MortgageCalculationType } from "../../models/mortgage";

export default tipsModule(
    "interest-discount-move-loan",
    (state: IRootState, configuration?) =>
        state?.userData?.household?.calculationType === MortgageCalculationType.move && isTipsActive(state, configuration?.discounts),
)(InterestDiscountTipsComponent);
