import * as React from "react";
import InputWizardComponent from "../input_wizard";
import HousingCalculationTypeStepComponent from "../steps/housing/housing_calculation_type_step";
import HousingValueStepComponent from "../steps/housing/housing_value_step";
import { IRootState, setHousingType, setMortgageCalculationType } from "../../reducers/rootReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { HousingType } from "../../models/user_data";
import HousingOccupantsStepComponent from "../steps/housing/housing_occupants_step";
import HousingAdultStepComponent from "../steps/housing/housing_adult_step";
import HousingMaintenanceStepComponent from "../steps/housing/housing_maintenance_step";
import HousingExistingValueStepComponent from "../steps/housing/housing_existing_value_step";
import HousingMortgageStepComponent from "../steps/housing/housing_mortgage_step";
import HousingMorgageAmortizationStepComponent from "../steps/housing/housing_mortgage_amortization_step";
import { useTracking } from "react-tracking";
import { trackUserInput, UserInputActions, StepNames } from "../../utility/analytics";
import { saveHousing, ViewMode, Sections, setNavigation } from "../../reducers/rootReducer";
import { getMortgageCalculationTypes } from "../../selectors/mortgage";
import HousingTypeStepComponent from "../steps/housing/housing_type_step";
import HousingFeeStepComponent from "../steps/housing/housing_fee_step";
import HousingPropertyTaxStep from "../steps/housing/housing_property_tax";
import { MortgageCalculationType } from "../../models/mortgage";
import { getHousingTypes } from "../../functions/housing_type";

export default function HousingWizard() {
    const dispatch = useDispatch();
    const tracking = useTracking();

    const { adults, calculationType, calculationTypes, housingType, loans, amortizationRequirementsAsDefault } = useSelector((state: IRootState) => {
        return {
            adults: state.userEditingData?.household?.adults || [],
            calculationType: state.userEditingData?.household?.calculationType,
            calculationTypes: getMortgageCalculationTypes(state.mortgage),
            housingType: state.userEditingData?.household?.housingType,
            loans: state.userEditingData?.household?.mortgages || [],
            amortizationRequirementsAsDefault: state.mortgage?.amortizationRequirementsAsDefault,
        };
    });

    function goToResult() {
        dispatch(saveHousing());
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_COMPLETED, StepNames.HOUSING));
        dispatch(
            setNavigation({
                viewMode: ViewMode.Result,
                section: Sections.Housing,
            }),
        );
    }

    function emitStepStarted() {
        tracking.trackEvent(trackUserInput(UserInputActions.STEP_STARTED, StepNames.HOUSING));
    }

    const skipCalculationTypeStep = calculationTypes.length === 1;
    if (!calculationType && skipCalculationTypeStep) {
        dispatch(
            setMortgageCalculationType({
                type: calculationTypes[0],
                useAmortizationRequirement: amortizationRequirementsAsDefault,
            }),
        );
    }

    const children = [];
    if (!skipCalculationTypeStep) {
        children.push(<HousingCalculationTypeStepComponent key="calculationtype"></HousingCalculationTypeStepComponent>);
    }

    const housingTypes = getHousingTypes();
    const skipHousingTypeStep = housingTypes.length === 1;
    if (!housingType && skipHousingTypeStep) {
        dispatch(setHousingType(housingTypes[0]));
    }

    if (!skipHousingTypeStep) {
        children.push(<HousingTypeStepComponent key="type"></HousingTypeStepComponent>);
    }

    if (calculationType === MortgageCalculationType.new) {
        children.push(
            <HousingValueStepComponent key="value"></HousingValueStepComponent>,
            <HousingOccupantsStepComponent key="occupants"></HousingOccupantsStepComponent>,
        );
        for (let key = 0; key < adults.length; ++key) {
            children.push(<HousingAdultStepComponent key={"adult" + key} index={key}></HousingAdultStepComponent>);
        }
        if (housingType === HousingType.condominium) {
            children.push(
                <HousingFeeStepComponent key="fee"></HousingFeeStepComponent>,
                <HousingMaintenanceStepComponent key="maintenance"></HousingMaintenanceStepComponent>,
            );
        }
        if (housingType === HousingType.house || housingType === HousingType.cottage) {
            children.push(
                <HousingMaintenanceStepComponent key="maintenance"></HousingMaintenanceStepComponent>,
                <HousingPropertyTaxStep key="propertytax"></HousingPropertyTaxStep>,
            );
        }
    } else if (calculationType === MortgageCalculationType.move) {
        children.push(<HousingExistingValueStepComponent key="existingvalue"></HousingExistingValueStepComponent>);
        for (let key = 0; key < loans.length; ++key) {
            children.push(<HousingMortgageStepComponent key={"mortgage" + key} index={key}></HousingMortgageStepComponent>);
            if (!amortizationRequirementsAsDefault) {
                children.push(
                    <HousingMorgageAmortizationStepComponent
                        key={"mortgage" + key + "_amortization"}
                        index={key}
                    ></HousingMorgageAmortizationStepComponent>,
                );
            }
        }
        children.push(<HousingOccupantsStepComponent key="occupants"></HousingOccupantsStepComponent>);
        for (let key = 0; key < adults.length; ++key) {
            children.push(<HousingAdultStepComponent key={"adult" + key} index={key}></HousingAdultStepComponent>);
        }
        if (housingType === HousingType.condominium) {
            children.push(
                <HousingFeeStepComponent key="fee"></HousingFeeStepComponent>,
                <HousingMaintenanceStepComponent key="maintenance"></HousingMaintenanceStepComponent>,
            );
        }
        if (housingType === HousingType.house || housingType === HousingType.cottage) {
            children.push(
                <HousingMaintenanceStepComponent key="maintenance"></HousingMaintenanceStepComponent>,
                <HousingPropertyTaxStep key="propertytax"></HousingPropertyTaxStep>,
            );
        }
    }

    return (
        <InputWizardComponent onFinalStep={goToResult} onFirstStep={emitStepStarted}>
            {children}
        </InputWizardComponent>
    );
}
