import * as React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AccessibleButton } from "./accessibility/accessible_components";
import { resetScenarios } from "../reducers/rootReducer";

const ResetAllScenarios = ({ showReset }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const reset = () => {
        dispatch(resetScenarios());
        showReset(false);
    };
    const handleClick = () => {
        showReset(false);
    };
    return (
        <div className="reset-scenario">
            <label>{t("scenario:reset-all-scenarios")}</label>
            <AccessibleButton onClick={reset}>{t("scenario:reset")}</AccessibleButton>
            <AccessibleButton className="outline" onClick={handleClick}>
                {t("common:wizard-back")}
            </AccessibleButton>
        </div>
    );
};

export default ResetAllScenarios;
