import React, { ReactHTML, KeyboardEvent } from "react";

import { useFocusVisible } from "use-focus-visible";
import classNames from "classnames";

type OnClick = () => void;

interface IAccessibleProps {
    className?: string;
    onClick: OnClick;
    role?: string;
    checked?: boolean;
    disabled?: boolean;
}

function createAccessibleComponent<T>(type: keyof ReactHTML) {
    return function (props: IAccessibleProps & React.HTMLProps<T>) {
        const { focusVisible, onFocus, onBlur } = useFocusVisible();
        const { disabled, onClick, className, ...rest } = props;

        const onKeyPress = (e: KeyboardEvent<T>) => {
            const enterOrSpace = e.code === "Enter" || e.code === "NumpadEnter" || e.code === "Space";

            if (enterOrSpace) {
                e.preventDefault();
                onClick();
            }
        };

        const classNameWithFocusVisible = classNames(className, {
            "focus-visible": focusVisible,
        });

        const accessibleProps = {
            ...rest,
            className: classNameWithFocusVisible,
            disabled,
            tabIndex: 0,
            role: props.role || "button",
            "aria-checked": props.checked === true ? true : props.checked === false ? false : undefined,
            onClick,
            onKeyUp: onKeyPress,
            onFocus,
            onBlur,
        };

        return React.createElement(type, accessibleProps);
    };
}

export default createAccessibleComponent;
