import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { setHousingValue, setHousingDownpayment, IRootState } from "../../../reducers/rootReducer";
import NumberComponent from "../../form_controls/number_component";
import { useTranslation } from "react-i18next";
import { HOUSING_PRICE_STEP, HOUSING_MAX_PRICE, DOWNPAYMENT_STEP, HOUSING_MIN_VALUE } from "../../../defaults";
import DisclaimerComponent from "../../typography/disclaimer_component";

export default function HousingValueStepComponent(props) {
    const { price, downpayment, minDownpaymentRate } = useSelector((state: IRootState) => {
        const household = state.userEditingData.household;

        return {
            price: (household && household.price) || 0,
            downpayment: (household && household.downpayment) || 0,
            minDownpaymentRate: state.minDownpaymentRate,
        };
    });

    const minDownpayment = price * minDownpaymentRate;
    // extract
    const minDownpaymentPercent = Math.round(minDownpaymentRate * 100);
    // extract
    const downpaymentRatio = Math.round(price <= 0 ? 100 : (downpayment / price) * 100);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    function onChangePrice(value: number) {
        props.handleNextButtonState(value <= 0);
        dispatch(setHousingValue(value));
    }

    function onChangeDownpayment(value: number) {
        props.handleNextButtonState(value < minDownpayment || price <= 0);
        if (price < value) {
            dispatch(setHousingValue(value));
        }

        dispatch(setHousingDownpayment(value));
    }

    React.useEffect(() => {
        props.handleNextButtonState(downpayment < minDownpayment || price <= 0);
        props.onValidate(true);
    });

    return (
        <IndataStepComponent active={props.active}>
            <NumberComponent
                name="housing_price"
                unit={t("common:sek")}
                onChange={onChangePrice}
                min={HOUSING_MIN_VALUE}
                step={HOUSING_PRICE_STEP}
                defaultMax={HOUSING_MAX_PRICE}
                label={t("housing:input-price")}
                value={price}
            ></NumberComponent>
            <NumberComponent
                name="housing_downpayment"
                unit={t("common:sek")}
                onChange={onChangeDownpayment}
                min={minDownpayment}
                step={DOWNPAYMENT_STEP}
                defaultMax={price}
                label={t("housing:input-downpayment", {
                    ratio: downpaymentRatio,
                })}
                value={downpayment}
            ></NumberComponent>
            <DisclaimerComponent>
                {t("housing:input-downpayment-disclaimer", {
                    ratio: minDownpaymentPercent,
                })}
            </DisclaimerComponent>
        </IndataStepComponent>
    );
}
