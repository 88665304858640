import * as React from "react";
import { formatLocalAmount } from "../../utility/number_formatter";
import { useTranslation } from "react-i18next";
import { AccessibleDiv } from "../accessibility/accessible_components";

interface NumberStepProps {
    label?: string;
    subLabel?: string;
    value: number;
    min: number;
    max: number;
    unit: string;
    icon: string;
    name: string;
    onChange: (value: number) => void;
}

export default function NumberStepComponent(props: NumberStepProps) {
    const { t } = useTranslation();
    const { onChange, label, subLabel, min, max, unit, icon, name } = props;
    const value = props.value || 0;

    const format = (value: number) => {
        return formatLocalAmount(value);
    };

    const stepUp = () => {
        onChange(Math.min(value + 1, max));
    };

    const stepDown = () => {
        onChange(Math.max(value - 1, min));
    };

    function iconClass() {
        if (!icon) {
            return "step-icon";
        }
        return "step-icon icon-" + icon;
    }

    return (
        <div data-cy={name} className="number-step-component">
            <label>{label}</label>
            {subLabel ? <p className="overline">{subLabel}</p> : null}
            <p className="input-label">
                {format(value)} {unit}
            </p>
            <div className="number-step">
                <AccessibleDiv
                    aria-label={t("aria:step-down")}
                    className="step-left icon-minus"
                    data-cy="step-down"
                    onClick={stepDown}
                ></AccessibleDiv>
                <div className={iconClass()}></div>
                <AccessibleDiv aria-label={t("aria:step-up")} className="step-right icon-plus" data-cy="step-up" onClick={stepUp}></AccessibleDiv>
            </div>
        </div>
    );
}
