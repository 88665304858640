import * as React from "react";
import IndataStepComponent from "../../indata_step";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTracking } from "react-tracking";
import { trackUserInput, UserInputActions } from "../../../utility/analytics";
import { getMortgageCalculationTypes } from "../../../selectors/mortgage";
import { IRootState, setMortgageCalculationType } from "../../../reducers/rootReducer";
import { MortgageCalculationType } from "models/mortgage";
import RadioComponent from "../../../components/form_controls/radio_component";

export default function HousingCalculationTypeStepComponent(props) {
    const { calculationTypes, calculationType } = useSelector((state: IRootState) => {
        return {
            calculationTypes: getMortgageCalculationTypes(state.mortgage),
            calculationType: state.userEditingData?.household?.calculationType,
        };
    });

    const { t } = useTranslation();
    const tracking = useTracking();

    const dispatch = useDispatch();

    function start(type: MortgageCalculationType) {
        new Promise<void>((resolve) => {
            dispatch(setMortgageCalculationType({ type }));
            resolve();
        }).then(() => {
            props.nextStep();
        });
        tracking.trackEvent(trackUserInput(UserInputActions.STARTED, `${type}-mortgages`));
    }

    React.useEffect(() => {
        props.onValidate(false);
    });

    const options = calculationTypes.map((type) => {
        return {
            value: type,
            label: t(`dynamic:mortgage-calculation-type-${type}`),
        };
    });
    return (
        <IndataStepComponent active={props.active}>
            <RadioComponent
                label={t("housing:input-calculation-type-instruction")}
                value={calculationType}
                options={options}
                onChange={start}
            ></RadioComponent>
        </IndataStepComponent>
    );
}
