import { tipsModule } from "../../utility/module_store";
import { IRootState } from "../../reducers/rootReducer";
import { MortgageCalculationType } from "../../models/mortgage";
import { isKalpPositiveWithExtraDownpaymentAsIncome, LowKalpTipsComponent } from "./low_kalp_component";
import { isBankKalpNegativeOrZero, isBankKalpPositivForLTV } from "../../functions/kalp";

function isTipsActive(state: IRootState) {
    return (
        isBankKalpNegativeOrZero(state) && isBankKalpPositivForLTV(state, state.minLTVForTips) && isKalpPositiveWithExtraDownpaymentAsIncome(state)
    );
}

export default tipsModule(
    "low-kalp-new-loan",
    (state: IRootState) => !!state.userData && state.userData.household.calculationType === MortgageCalculationType.new && isTipsActive(state),
)(LowKalpTipsComponent);
