import { DataPoint } from "../models/result";

export function sumArrays(arrays: Array<Array<number>>): Array<number> {
    return arrays.reduce((accumulated, current) => {
        if (!accumulated || accumulated.length === 0) {
            return current;
        }

        return accumulated.map((value, index) => value + current[index]);
    });
}

export function sumDatapoints(datapoints: Array<DataPoint>): DataPoint {
    return datapoints.reduce((accumulated, current) => {
        return {
            now: accumulated.now + current.now,
            future: sumArrays([accumulated.future, current.future]),
        };
    });
}

export function getFutureValue(numbers: Array<number>): number {
    return numbers.slice(-1)[0];
}

export function renderScenarioNow(data: DataPoint): boolean {
    if (typeof data.scenarioNow === "undefined") {
        return false;
    }

    if (data.now === data.scenarioNow) {
        return false;
    }

    return true;
}

export function renderScenarioFuture(data: DataPoint): boolean {
    if (typeof data.scenarioFuture === "undefined") {
        return false;
    }

    if (getFutureValue(data.future) === getFutureValue(data.scenarioFuture)) {
        return false;
    }

    return true;
}
