import {
    HIGH_AMORTIZATION_RATE_REQUIREMENT,
    HIGH_LOAN_TO_VALUE_THRESHOLD,
    LOAN_TO_INCOME_THRESHOLD,
    INCOME_BASED_REQUIREMENT,
    LOW_AMORTIZATION_RATE_REQUIREMENT,
    LOW_LOAN_TO_VALUE_THRESHOLD,
    ZERO_AMORTIZATION_RATE,
    MONTHS_IN_YEAR,
} from "../defaults";

interface ICapitalRateInput {
    price: number;
    loan: number;
}
interface IIncomeRateInput {
    allMortgagesAmount: number;
    income: number;
}
interface ILoanToIncomeRatioInput {
    allMortgagesAmount: number;
    yearlyIncome: number;
}
interface IMinAmortizationInput extends ICapitalRateInput {
    income: number;
    additionalMortgageAmount: number;
}

export function calculateAmortizationRate({ price, amortization }): number {
    return Math.round(price <= 0 ? 100 : ((amortization * 12) / price) * 100);
}

export function calculateDownpayment({ price, loanToValueFactor }) {
    return price * (1 - loanToValueFactor);
}

export function loanToValueRatio({ price, loan }: ICapitalRateInput): number {
    return loan / price;
}

export function loanToIncomeRatio({ allMortgagesAmount, yearlyIncome }: ILoanToIncomeRatioInput): number {
    return allMortgagesAmount / yearlyIncome;
}

export function minAmortizationRate({ price, loan, income, additionalMortgageAmount }: IMinAmortizationInput): number {
    return (
        capitalRate({ price, loan }) +
        incomeRate({
            allMortgagesAmount: loan + additionalMortgageAmount,
            income,
        })
    );
}

function capitalRate({ price, loan }: ICapitalRateInput): number {
    const ratio = loanToValueRatio({ price, loan });

    if (ratio > HIGH_LOAN_TO_VALUE_THRESHOLD) {
        return HIGH_AMORTIZATION_RATE_REQUIREMENT;
    } else if (ratio > LOW_LOAN_TO_VALUE_THRESHOLD) {
        return LOW_AMORTIZATION_RATE_REQUIREMENT;
    } else {
        return ZERO_AMORTIZATION_RATE;
    }
}

function incomeRate({ allMortgagesAmount, income }: IIncomeRateInput): number {
    const yearlyIncome = income * MONTHS_IN_YEAR;

    const ratio = loanToIncomeRatio({ allMortgagesAmount, yearlyIncome });

    if (ratio <= LOAN_TO_INCOME_THRESHOLD) {
        return ZERO_AMORTIZATION_RATE;
    } else {
        return INCOME_BASED_REQUIREMENT;
    }
}
