import { IRootState } from "../reducers/rootReducer";

export interface ITipsModuleProps {
    rootState: IRootState;
    configuration?: any; // eslint-disable-line
    onActivated: () => void;
}

export interface ITipsModule {
    id: string;
    activationFunction: (rootState: IRootState, configuration?: any) => boolean; // eslint-disable-line
    component: React.FunctionComponent<ITipsModuleProps> | React.ComponentClass<ITipsModuleProps>;
}

export class ModuleStore {
    public static tipsModules: {
        [id: string]: ITipsModule;
    } = {};

    public static getTipsModule(id: string): ITipsModule {
        return ModuleStore.tipsModules[id];
    }
}

export function tipsModule(id: string, activationFunction: (rootState: IRootState) => boolean) {
    return function (component: React.FunctionComponent<ITipsModuleProps> | React.ComponentClass<ITipsModuleProps>) {
        ModuleStore.tipsModules[id] = {
            id,
            activationFunction,
            component,
        };
        return component;
    };
}
